import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import {
  selectSonraiUsers,
  selectAllRoles,
} from 'containers/SonraiData/selectors'
import { selectAssigningUsers } from './selectors'
import { updateUserInSwimlane } from './actions'

import { List } from 'immutable'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import Button from 'components/Button'
import TextLink from 'components/TextLink'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'
import SelectBar from 'components/SelectBar'

export class AddAssignmentModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      usersSelected: [],
      rolesSelected: [],
    }
  }

  changeUsersSelected = users => {
    this.setState({ usersSelected: users })
  }

  changeRolesSelected = roles => {
    this.setState({ rolesSelected: roles })
  }

  assignUsers = () => {
    const assignUs = []
    this.state.usersSelected.forEach(user => {
      this.state.rolesSelected.forEach(role => {
        assignUs.push({
          roleSrn: role.value,
          userSrn: user.value,
          scope: this.props.swimlaneResourceId,
        })
      })
    })
    this.props.toggle()
    this.props.updateUserInSwimlane({ create: assignUs })
  }

  getValidityMessage = () => {
    const assignedRoles = this.props.sonraiUsers.map(user => {
      return user
        .getIn(['roleAssignments', 'items'], List())
        .filter(item => item.get('scope') === this.props.swimlaneResourceId)
        .map(roleAssignment =>
          roleAssignment.getIn(['role', 'items', 0, 'srn'])
        )
    })

    let message = null

    if (this.state.usersSelected && this.state.rolesSelected) {
      this.state.usersSelected.forEach(userOpt => {
        const userSrn = userOpt.value
        this.state.rolesSelected.forEach(roleOpt => {
          if (
            assignedRoles.get(userSrn) &&
            assignedRoles.get(userSrn).includes(roleOpt.value)
          ) {
            message = `User ${userOpt.label} already has role ${roleOpt.label} for this swimlane`
          }
        })
      })
    }

    return message
  }

  getUserModalBody = () => {
    const roles = this.props.allRoles.get('data').map(role => ({
      label: role.get('name'),
      value: role.get('srn'),
    }))

    const users = this.props.sonraiUsers
      .toList()
      .map(user => ({ label: user.get('name'), value: user.get('srn') }))

    const invalidMessage = this.getValidityMessage()
    let disabled = !!invalidMessage

    if (!this.state.rolesSelected || this.state.rolesSelected.length < 1) {
      if (!this.state.usersSelected || !this.state.usersSelected.length < 1) {
        if (this.props.assigningUsers) {
          disabled = true
        }
      }
    }

    return (
      <div>
        <div style={{ marginBottom: '1em' }}>
          Select User(s)
          <SelectBar
            isMulti
            options={users.toJS()}
            onChange={this.changeUsersSelected}
            value={this.state.usersSelected}
          />
        </div>
        <div style={{ marginBottom: '1em' }}>
          Select Role(s)
          <SelectBar
            isMulti
            options={roles.toJS()}
            onChange={this.changeRolesSelected}
            value={this.state.rolesSelected}
          />
          {invalidMessage && (
            <p style={{ color: this.props.theme.fail, fontSize: '0.9em' }}>
              {invalidMessage}
            </p>
          )}
        </div>
        <div style={{ display: 'grid', gridTemplateColumns: 'auto auto' }}>
          <TextLink
            color="secondary"
            onClick={this.props.toggle}
            style={{ marginRight: '1em' }}
          >
            Cancel
          </TextLink>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <WithPermission permissionName="edit.swimlanes">
              <Button
                disabled={disabled}
                onClick={this.assignUsers}
                color="primary"
              >
                Assign User(s)
              </Button>
            </WithPermission>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <Modal isOpen={true} toggle={this.props.toggle}>
        <ModalHeader>Select User(s) and Role(s)</ModalHeader>
        <ModalBody>{this.getUserModalBody()}</ModalBody>
      </Modal>
    )
  }
}

AddAssignmentModal.propTypes = {
  sonraiUsers: ImmutablePropTypes.map,
  allRoles: ImmutablePropTypes.map,
  assigningUsers: PropTypes.bool,
  swimlaneResourceId: PropTypes.string,
  theme: themeShape,
  toggle: PropTypes.func.isRequired,
  updateUserInSwimlane: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  sonraiUsers: selectSonraiUsers,
  allRoles: selectAllRoles,
  assigningUsers: selectAssigningUsers,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      updateUserInSwimlane,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, themeable)(AddAssignmentModal)
