import React, { Fragment } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { Button, Form, FormGroup, Label } from 'reactstrap'
import MultiValueInput from 'components/MultiValueInput'
import Icon from 'components/Icon'
import TextLink from 'components/TextLink'
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import AccountSelector from 'components/AccountSelector'
import SwimlaneTagManager from 'containers/SwimlaneManager/SwimlaneTagManager'
import _ from 'lodash'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

export class EditFilters extends React.Component {
  constructor(props) {
    super(props)
    const swombo = props.swimlane.toJS()
    this.state = {
      swimlaneAccounts: swombo.accounts ? swombo.accounts : [],
      swimlaneTags: swombo.tags ? swombo.tags : [],
      swimlaneNames: swombo.names ? swombo.names : [],
      swimlaneResourceIds: swombo.resourceIds ? swombo.resourceIds : [],
      advanced: true,
    }

    this.styles = {
      submit: {
        margin: '1em 0',
        float: 'right',
      },
      modalBody: {
        maxHeight: '70vh',
        overflow: 'auto',
      },
    }
  }

  doSave = () => {
    this.props.save({
      srn: this.props.swimlane.get('srn'),
      swimlane: {
        ...this.props.swimlane.toJS(),
        accounts: this.state.swimlaneAccounts,
        tags: this.state.swimlaneTags,
        resourceIds: this.state.swimlaneResourceIds,
        names: this.state.swimlaneNames,
      },
    })
    this.props.toggle()
  }

  getTagOptions = () => {
    const serverTags = this.props.tags
      .filter(tag => !!tag.get('key'))
      .sortBy(tag => tag.get('key').toLowerCase())
      .toJS()
      .map(tag => ({ value: tag.key, label: tag.key }))

    const additionalOptions = this.state.swimlaneTags.map(tagStr => ({
      value: tagStr,
      label: tagStr,
    }))

    return serverTags.concat(additionalOptions)
  }

  setSwimlaneTags = newTagsVal => {
    if (!newTagsVal) {
      this.setState({
        swimlaneTags: [],
      })
    } else {
      const { value } = newTagsVal
      if (Array.isArray(value)) {
        this.setState({
          swimlaneTags: value,
        })
      } else {
        let arr = [...this.state.swimlaneTags]

        const { value, key, toRemove } = newTagsVal
        const combineStr = key !== '' || value !== '' ? `${key}:${value}` : key

        if (toRemove) {
          const index = arr.findIndex(tag => tag.includes(combineStr))
          arr.splice(index, 1)
          this.setState({ swimlaneTags: arr })
        } else {
          this.setState(state => ({
            swimlaneTags: [...state.swimlaneTags, combineStr],
          }))
        }
      }
    }
  }

  setSwimlaneResourceIds = values => {
    this.setState({
      swimlaneResourceIds: values,
    })
  }

  setSwimlaneAccounts = newAccountsVal => {
    this.setState({
      swimlaneAccounts: newAccountsVal,
    })
  }

  setSwimlaneTitle = e => {
    this.setState({
      swimlaneTitle: e.target.value,
    })
  }

  setSwimlaneDescription = e => {
    this.setState({
      swimlaneDescription: e.target.value,
    })
  }

  setSwimlaneNames = values => {
    this.setState({
      swimlaneNames: values,
    })
  }

  renderButtons = nameIsValid => {
    // Buttons should always be positive action on the right,
    // because people are use to things moving to the right as moving forward, and left as backwards
    // shut up nerd
    // lol, good one tim

    const {
      swimlaneAccounts,
      swimlaneNames,
      swimlaneTags,
      swimlaneResourceIds,
    } = this.state

    const hasNoFilters =
      _.isEmpty(swimlaneAccounts) &&
      _.isEmpty(swimlaneNames) &&
      _.isEmpty(swimlaneTags) &&
      _.isEmpty(swimlaneResourceIds)

    if (this.props.updating) {
      return (
        <Button color="primary" disabled>
          <Icon fa name="sync" spin />
        </Button>
      )
    }

    return (
      <Fragment>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {hasNoFilters && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Icon
                fa
                name="exclamation-circle"
                color={this.props.theme.warn}
              />
              <span
                style={{
                  color: this.props.theme.warn,
                  fontSize: '0.85rem',
                  margin: '0rem 1rem 0rem 0.5rem',
                }}
              >
                No resources will match this Swimlane unless at least one filter
                is applied.
              </span>
            </div>
          )}
          <TextLink
            color="secondary"
            onClick={this.props.close}
            style={{ marginRight: '1em' }}
          >
            Cancel
          </TextLink>
          <Button color="primary" onClick={this.doSave} disabled={!nameIsValid}>
            Save
          </Button>
        </div>
      </Fragment>
    )
  }

  isNameValid = () => {
    const { swimlane, swimlanes } = this.props
    const title = this.state.swimlaneTitle.trim().toLowerCase()

    if (title === 'all' || /\s/g.test(title)) {
      return false
    }

    let filteredSwimlanes = swimlanes

    if (swimlanes) {
      if (swimlane) {
        filteredSwimlanes = filteredSwimlanes.filter(
          sl => sl.get('srn') !== swimlane.get('srn')
        )
      }

      if (
        filteredSwimlanes.find(
          swimlane => swimlane.get('title', '').toLowerCase() === title
        )
      ) {
        return false
      }
    }

    return true
  }

  render() {
    const selectStyles = {
      menuList: styles => {
        return {
          ...styles,
          maxHeight: '175px',
        }
      },
    }

    return (
      <Fragment>
        <ModalHeader toggle={this.props.toggleModal}>
          Edit Swimlane Filters
        </ModalHeader>
        <ModalBody style={this.styles.modalBody}>
          <Form>
            <FormGroup>
              <Label for="name">Restrict to Accounts</Label>
              <AccountSelector
                value={this.state.swimlaneAccounts.map(account => ({
                  label: account.value || account,
                  value: account.value || account,
                }))}
                onChange={this.setSwimlaneAccounts}
                accounts={this.props.accounts}
                styles={selectStyles}
                hasAccountNumber
              />
            </FormGroup>
            <FormGroup>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Label for="tags">Matches Resource Tags</Label>
                <TextLink
                  onClick={() =>
                    this.setState(oldState => ({
                      advanced: !oldState.advanced,
                    }))
                  }
                  color="primary"
                  style={{ fontSize: '0.9rem', marginTop: '1em' }}
                >
                  {!this.state.advanced ? 'Tag Builder' : 'Basic Tag Input'}
                </TextLink>
              </div>
              <SwimlaneTagManager
                isAdvanced={this.state.advanced}
                swimlaneTags={this.state.swimlaneTags}
                onChange={this.setSwimlaneTags}
                fetchTags={this.props.fetchTags}
                options={this.getTagOptions()}
                selectStyles={selectStyles}
                isLoadingTagValues={this.props.isLoadingTagValues}
                tagValues={this.props.tagValues}
                getTagValues={this.props.getTagValues}
              />
            </FormGroup>

            <FormGroup>
              <Label for="resourceNames">
                Matches Resource Names{' '}
                <span style={{ fontSize: '0.8em' }}>
                  (Use * to define a wildcard match)
                </span>
              </Label>
              <MultiValueInput
                value={this.state.swimlaneNames}
                onChange={this.setSwimlaneNames}
              />
            </FormGroup>
            <FormGroup>
              <Label for="resourceIds">
                Matches Resource Ids{' '}
                <span style={{ fontSize: '0.8em' }}>
                  (Use * to define a wildcard match)
                </span>
              </Label>
              <MultiValueInput
                value={this.state.swimlaneResourceIds}
                onChange={this.setSwimlaneResourceIds}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter> {this.renderButtons(true)} </ModalFooter>
      </Fragment>
    )
  }
}

EditFilters.propTypes = {
  theme: themeShape,
  swimlane: ImmutablePropTypes.map,
  accounts: ImmutablePropTypes.iterable,
  swimlanes: ImmutablePropTypes.map,
  save: PropTypes.func,
  toggleModal: PropTypes.func,
  close: PropTypes.func,
  toggle: PropTypes.func,
  fetchTags: PropTypes.func,
  getTagValues: PropTypes.func,
  updating: PropTypes.bool,
  isLoadingTagValues: PropTypes.bool,
  tagValues: ImmutablePropTypes.iterable,
  tags: ImmutablePropTypes.list,
}

export default themeable(EditFilters)
