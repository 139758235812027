import { all, put, takeLatest, select, call } from 'redux-saga/effects'
import gql from 'graphql-tag'
import { List } from 'immutable'
import _ from 'lodash'
import { getClient } from 'apolloClient'
import {
  addSwimlane,
  removeSwimlane,
  updateSwimlane as updateSwimlaneAction,
  updateUserRolesSuccess,
} from 'containers/SonraiData/actions'
import { selectSwimlanes } from 'containers/SonraiData/selectors'
import {
  formatUpdateSwimlaneTags,
  formatCreateSwimlaneTags,
} from 'utils/graphDataUtils'

import {
  createSwimlaneSuccess,
  deleteSwimlaneSuccess,
  updateSwimlaneSuccess,
  setPreviewResults,
  setTagValues,
  setControlFrameworksForSwimlane,
  setBotValues,
  updateSwimlanesInControlFrameworkSuccess,
  updateUserInSwimlaneSuccess,
  setUpdateSwimlaneError,
} from './actions'
import { setShouldUpdateNodeSwimlanes } from 'containers/NodeSolutionCenter/actions'

import {
  CREATE_SWIMLANE,
  DELETE_SWIMLANE,
  UPDATE_SWIMLANE,
  GET_PREVIEW_RESULTS,
  GET_TAG_VALUES,
  GET_CONTROL_FRAMEWORKS_FOR_SWIMLANE,
  GET_BOT_VALUES,
  UPDATE_SWIMLANES_IN_CONTROL_FRAMEWORK,
  UPDATE_USER_IN_SWIMLANE,
} from './constants'

import { GET_ALL_BOTS } from 'containers/BotManagement/static-queries'

function* createSwimlane(action) {
  let { swimlane, bots } = action.payload
  swimlane.tags = formatCreateSwimlaneTags(swimlane.tags)
  let botValues = []
  try {
    const client = getClient()
    const results = yield client.mutate({
      mutation: gql`
        mutation createSwimlane($swimlane: SwimlaneCreator!) {
          CreateSwimlane(value: $swimlane) {
            description
            label
            title
            srn
            defaultImportance
            createdBy
            sid
            preventionEnabled
            lastModified
            createdDate
            name
            accounts
            names
            resourceIds
            tags
            resourceId
          }
        }
      `,
      variables: { swimlane },
    })

    const createdSwimlane = results.data.CreateSwimlane

    if (bots && !_.isEmpty(bots)) {
      for (let i = 0; i < bots.length; i++) {
        const bot = yield call(
          createBotAssignement,
          bots[i].value,
          createdSwimlane.srn
        )
        botValues.push(bot)
      }
    }

    createdSwimlane.bots = botValues
    yield put(createSwimlaneSuccess(createdSwimlane))
    yield put(addSwimlane(createdSwimlane))
    yield put(setShouldUpdateNodeSwimlanes(true))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error creating swimlanes', e)
  }
}

function* createBotAssignement(botSrn, contentSrn) {
  try {
    const client = getClient()
    const response = yield client.mutate({
      mutation: gql`
      mutation attachBotToSwimlane { 
          CreateBotAssignments(input: { 
              botSrn: "${botSrn}",
              contentSrn: "${contentSrn}"
          }) {
            items { 
              bot {
                srn
                title
                url
              } 
            }
          }
      } 
      `,
    })
    if (response.data.CreateBotAssignments) {
      if (!_.isEmpty(response.data.CreateBotAssignments.items)) {
        const bot = _.get(
          _.first(response.data.CreateBotAssignments.items),
          'bot'
        )
        return bot
      }
    } else {
      return {}
    }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error creating bot assignment', e)
  }
}

function* removeBotAssignemnt(assignmentSrn) {
  try {
    const client = getClient()
    const res = yield client.mutate({
      mutation: gql`
      mutation removeBotFromSwimlane {
        RemoveBotAssignments(input: { srn: "${assignmentSrn}" }) {
          items
        }
      }
      `,
    })
    return _.first(res.data.RemoveBotAssignments.items)
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error removing bot assignment', e)
  }
}

function* previewSwimlane(action) {
  try {
    const params = { ...action.payload }
    let coupleOfOrsUpInHere = ''
    if (params.accounts && params.accounts.length > 0) {
      params.accounts.forEach(account => {
        if (params.resourceIds) {
          params.resourceIds.forEach(resourceId => {
            const letters = resourceId.split('')
            const regex = letters
              .map(letter => {
                if (letter === '*') {
                  return '.*'
                } else {
                  return `[${letter.toUpperCase()}${letter.toLowerCase()}]`
                }
              })
              .join('')
            coupleOfOrsUpInHere += `
              { account: { op: IN_LIST values: ["${account}"] } resourceId: { op: REGEX value: "${regex}" caseSensitive: false } }`
          })
        }
        if (params.resourceNames) {
          params.resourceNames.forEach(resourceName => {
            const letters = resourceName.split('')
            const regex = letters
              .map(letter => {
                if (letter === '*') {
                  return '.*'
                } else {
                  return `[${letter.toUpperCase()}${letter.toLowerCase()}]`
                }
              })
              .join('')
            coupleOfOrsUpInHere += `
              { account: { op: IN_LIST values: ["${account}"] } name: { op: REGEX value: "${regex}" caseSensitive: false } }`
          })
        }
        if (params.tags) {
          params.tags.forEach(tag => {
            const letters = tag.split('')
            const regex = letters
              .map(letter => {
                if (letter === '*') {
                  return '.*'
                } else {
                  return `[${letter.toUpperCase()}${letter.toLowerCase()}]`
                }
              })
              .join('')
            coupleOfOrsUpInHere += `
              { account: { op: IN_LIST values: ["${account}"] } tagSet: { op: REGEX value: "${regex}" caseSensitive: false } }`
          })
        }
      })
    } else {
      if (params.resourceIds) {
        params.resourceIds.forEach(resourceId => {
          const letters = resourceId.split('')
          const regex = letters.map(letter => {
            if (letter === '*') {
              return '.*'
            } else {
              return `[${letter.toUpperCase()}${letter.toLowerCase()}]`
            }
          })
          coupleOfOrsUpInHere += `
            { resourceId: { op: REGEX value: "${regex}" caseSensitive: false } }`
        })
      }
      if (params.resourceNames) {
        params.resourceNames.forEach(resourceName => {
          const letters = resourceName.split('')
          const regex = letters
            .map(letter => {
              if (letter === '*') {
                return '.*'
              } else {
                return `[${letter.toUpperCase()}${letter.toLowerCase()}]`
              }
            })
            .join('')
          coupleOfOrsUpInHere += `
            { name: { op: REGEX value: "${regex}" caseSensitive: false } }`
        })
      }
      if (params.tags) {
        params.tags.forEach(tag => {
          const letters = tag.split('')
          const regex = letters
            .map(letter => {
              if (letter === '*') {
                return '.*'
              } else {
                return `[${letter.toUpperCase()}${letter.toLowerCase()}]`
              }
            })
            .join('')
          coupleOfOrsUpInHere += `
            { tagSet: { op: REGEX value: "${regex}" caseSensitive: false } }`
        })
      }
    }
    // because it's tall and an abomination against the lord
    let queryOfBabel = `
      query preview {
        Resources(where: {
          or: [
            ${coupleOfOrsUpInHere}
          ],
          and: [
            { highestAlertSeverity: { op: GT, value: 0 } }
          ]
        }) {
          items(limit: 75) {
            account
            friendlyName
            name
            resourceId
            srn
            tagSet
          }
        }
      }
    `

    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${queryOfBabel}
      `,
    })

    yield put(setPreviewResults(results.data.Resources.items))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error creating swimlanes', e)
  }
}

function* deleteSwimlane(action) {
  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`
        mutation deleteSwimlane($srn: ID!) {
          DeleteSwimlane(srn: $srn)
        }
      `,
      variables: { srn: action.payload.srn },
    })

    if (result.errors && result.errors.length > 0) {
      result.errors.map(err => {
        throw new Error(err.message)
      })
    }

    yield put(deleteSwimlaneSuccess(action.payload.title))
    yield put(removeSwimlane(action.payload.title))
    yield put(setShouldUpdateNodeSwimlanes(true))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error deleting swimlanes', e)
  }
}

function* updateSwimlane(action) {
  const newSwimlane = action.payload.swimlane
  const swimlanes = yield select(selectSwimlanes)
  const originalSwimlane = swimlanes.find(
    swimlane => swimlane.get('srn') === action.payload.srn
  )

  const oldTags = originalSwimlane.get('tags') || List()
  const newTags = newSwimlane.tags || []
  const tagsUpdater = {
    add: [],
    remove: [],
  }

  const oldAccounts = originalSwimlane.get('accounts') || List()
  const newAccounts = newSwimlane.accounts || []
  const accountsUpdater = {
    add: [],
    remove: [],
  }

  const oldNames = originalSwimlane.get('names') || List()
  const newNames = newSwimlane.names || []
  const namesUpdater = {
    add: [],
    remove: [],
  }

  const oldBots = originalSwimlane
    .get('bots', List())
    .map(bot => bot.get('srn'))
    .toJS()

  const newBots =
    (newSwimlane.bots && newSwimlane.bots.map(bot => bot.value || bot.srn)) ||
    []

  const botsUpdater = {
    add: [],
    remove: [],
  }
  const oldResourceIds = originalSwimlane.get('resourceIds') || List()
  const newResourceIds = newSwimlane.resourceIds || []
  const resourceIdsUpdater = {
    add: [],
    remove: [],
  }

  if (originalSwimlane) {
    oldTags.forEach(tag => {
      if (!newTags.includes(tag)) {
        tagsUpdater.remove.push(tag)
      }
    })

    newTags.forEach(tag => {
      if (!oldTags.includes(tag)) {
        tagsUpdater.add.push(tag)
      }
    })

    oldAccounts.forEach(account => {
      if (!newAccounts.includes(account)) {
        accountsUpdater.remove.push(account)
      }
    })

    newAccounts.forEach(account => {
      if (!oldAccounts.includes(account)) {
        accountsUpdater.add.push(account)
      }
    })

    oldNames.forEach(name => {
      if (!newNames.includes(name)) {
        namesUpdater.remove.push(name)
      }
    })

    newNames.forEach(name => {
      if (!oldNames.includes(name)) {
        namesUpdater.add.push(name)
      }
    })

    oldResourceIds.forEach(name => {
      if (!newResourceIds.includes(name)) {
        resourceIdsUpdater.remove.push(name)
      }
    })

    newResourceIds.forEach(name => {
      if (!oldResourceIds.includes(name)) {
        resourceIdsUpdater.add.push(name)
      }
    })

    oldBots.forEach(bot => {
      if (!newBots.includes(bot)) {
        botsUpdater.remove.push(bot)
      }
    })

    newBots.forEach(bot => {
      if (!oldBots.includes(bot)) {
        botsUpdater.add.push(bot)
      }
    })

    if (botsUpdater.remove.length > 0) {
      botsUpdater.remove = botsUpdater.remove.map(srn => {
        const botObj = originalSwimlane
          .get('bots')
          .find(bot => bot.get('srn') === srn)
        return botObj.get('botAssignmentSrn')
      })
    }
  }

  try {
    const client = getClient()
    const results = yield client.mutate({
      mutation: gql`
        mutation updateSwimlane($swimlane: SwimlaneUpdater!, $srn: ID!) {
          UpdateSwimlane(srn: $srn, value: $swimlane) {
            description
            label
            title
            srn
            defaultImportance
            createdBy
            sid
            preventionEnabled
            lastModified
            createdDate
            name
            accounts
            names
            resourceIds
            tags
            resourceId
          }
        }
      `,
      variables: {
        srn: action.payload.srn,
        swimlane: {
          title: newSwimlane.title,
          description: newSwimlane.description,
          defaultImportance: newSwimlane.defaultImportance,
          tags: formatUpdateSwimlaneTags(tagsUpdater),
          accounts: accountsUpdater,
          names: namesUpdater,
          resourceIds: resourceIdsUpdater,
          preventionEnabled: newSwimlane.preventionEnabled,
        },
      },
    })

    if (results.errors) {
      throw new Error(
        _.get(results, ['errors', 0, 'message'], 'Error updating Swimlane')
      )
    }

    let updatedBots = originalSwimlane.get('bots', List()).toJS()

    if (botsUpdater.remove && !_.isEmpty(botsUpdater.remove)) {
      for (let i = 0; i < updatedBots.length; i++) {
        const bot = updatedBots[i]
        if (botsUpdater.remove.includes(bot.botAssignmentSrn)) {
          const hasBeenDeleted = yield call(
            removeBotAssignemnt,
            bot.botAssignmentSrn
          )
          if (hasBeenDeleted) {
            //aka if we got a true response back from the removeBotAssignement endpoint
            updatedBots.splice(i, 1)
          }
        }
      }
    }

    if (botsUpdater.add && !_.isEmpty(botsUpdater.add)) {
      for (let i = 0; i < botsUpdater.add.length; i++) {
        const bot = yield call(
          createBotAssignement,
          botsUpdater.add[i],
          action.payload.srn
        )

        updatedBots.push(bot)
      }
    }

    let updatedSwimlane = results.data.UpdateSwimlane
    updatedSwimlane.bots = updatedBots

    yield put(updateSwimlaneSuccess(updatedSwimlane))
    yield put(
      updateSwimlaneAction({
        srn: action.payload.srn,
        swimlane: updatedSwimlane,
      })
    )
    yield put(setShouldUpdateNodeSwimlanes(true))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error updating swimlanes', e)
    yield put(
      setUpdateSwimlaneError({ srn: action.payload.srn, error: e.message })
    )
  }
}

function* getTagValues(action) {
  const { payload } = action
  if (!payload) {
    yield put(setTagValues(null))
  } else {
    try {
      const client = getClient()
      const results = yield client.query({
        query: gql`
          query getTagValuesByKey($key: String!) {
            Tags(where: { key: { value: $key } }) {
              group {
                key {
                  key
                  value
                }
              }
            }
          }
        `,
        variables: { key: action.payload },
      })

      const items = results.data.Tags.group
      const values = items.map(item => item.key.value)
      yield put(setTagValues(values))
    } catch (e) {
      //eslint-disable-next-line no-console
      console.error('error getting tag values ', e)
    }
  }
}

function* getControlFrameworksForSwimlane(action) {
  const CONTROL_FRAMEWORK_SWIMLANE = `
    query getControlFrameworks($srns: [String]) {
      ControlFrameworks(where: {
        swimlaneSRNs: { op: IN_LIST, values: $srns }
      }) {
        items {
          description
          loadId
          policyCount: contains {
            count
          }
          swimlaneSRNs
          shortDescription
          enabled
          title
          preventionEnabled
          remediationEnabled
          createdBy
          sid
          lastModified
          createdDate
          name
          srn
          resourceId
        }
      }
    }
  `
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${CONTROL_FRAMEWORK_SWIMLANE}
      `,
      variables: { srns: [action.payload.swimlane] },
    })
    const frameworks = _.get(
      results,
      ['data', 'ControlFrameworks', 'items'],
      []
    )

    yield put(
      setControlFrameworksForSwimlane({
        swimlane: action.payload.swimlane,
        data: frameworks,
      })
    )
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error getting tag values ', e)
  }
}

function* updateSwimlaneseInCF(action) {
  const UPDATE_CF_MUTATION = `
    mutation updateSwimlanesInCF($srn: ID!, $newCF: ControlframeworkUpdater!) {
      UpdateControlframework(srn: $srn, value: $newCF) {
        description
        loadId
        policyCount: contains {
          count
        }
        swimlaneSRNs
        shortDescription
        enabled
        title
        preventionEnabled
        remediationEnabled
        createdBy
        sid
        lastModified
        createdDate
        name
        srn
        resourceId
      }
    }
  `

  try {
    const client = getClient()
    const result = yield client.mutate({
      mutation: gql`
        ${UPDATE_CF_MUTATION}
      `,
      variables: {
        srn: action.payload.srn,
        newCF: {
          swimlaneSRNs: {
            add: action.payload.add ? action.payload.add : [],
            remove: action.payload.remove ? action.payload.remove : [],
          },
        },
      },
    })
    const framework = _.get(result, ['data', 'UpdateControlframework'], false)
    if (framework) {
      yield put(
        updateSwimlanesInControlFrameworkSuccess({
          srn: action.payload.srn,
          framework: framework,
        })
      )
    }
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error getting bot values ', e)
  }
}

function* getBots() {
  try {
    const client = getClient()
    const results = yield client.query({
      query: gql`
        ${GET_ALL_BOTS}
      `,
    })
    yield put(setBotValues(results.data.Bots.items))
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error getting bot values ', e)
  }
}

function* updateUserInSwimlane(action) {
  const CREATE_USER_IN_SWIMLANE_MUTATION = `
    mutation addUserToSwimlane($input: [SonraiRoleAssignmentCreator!]!) {
      CreateSonraiRoleAssignments(input: $input) {
        items {
          user {
            items {
              srn
              roleAssignments {
                items {
                  srn
                  scope
                  role {
                    items {
                      srn
                      name
                      description
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `

  const DELETE_USER_IN_SWIMLANE_MUTATION = `
    mutation deleteRoleAssignment($input: [SonraiRoleAssignmentDeleter!]!) {
      DeleteSonraiRoleAssignments(input: $input) {
        items {
          srn
          deleted
        }
      }
    }
  `

  try {
    const client = getClient()
    if (action.payload.create) {
      const results = yield client.mutate({
        mutation: gql`
          ${CREATE_USER_IN_SWIMLANE_MUTATION}
        `,
        variables: {
          input: [...action.payload.create],
        },
      })

      const newUsers = _.get(
        results,
        ['data', 'CreateSonraiRoleAssignments', 'items'],
        []
      )

      for (const user of newUsers) {
        const newAss = {
          userSrn: _.get(user, ['user', 'items', 0, 'srn'], ''),
          addedRoles: _.get(
            user,
            ['user', 'items', 0, 'roleAssignments', 'items'],
            []
          ),
          deletedSrns: [],
        }
        yield put(updateUserRolesSuccess(newAss))
      }
    }

    if (action.payload.delete) {
      yield client.mutate({
        mutation: gql`
          ${DELETE_USER_IN_SWIMLANE_MUTATION}
        `,
        variables: {
          input: action.payload.delete.map(deleteGuy => ({
            srn: deleteGuy.assignmentSrn,
          })),
        },
      })

      for (const deleteGuy of action.payload.delete) {
        const newAss = {
          userSrn: deleteGuy.userSrn,
          addedRoles: [],
          deletedSrns: [deleteGuy.assignmentSrn],
        }
        yield put(updateUserRolesSuccess(newAss))
      }
    }

    yield put(updateUserInSwimlaneSuccess())
  } catch (e) {
    //eslint-disable-next-line no-console
    console.error('error getting bot values ', e)
  }
}

function* swimlaneDetailsSaga() {
  yield all([
    takeLatest(CREATE_SWIMLANE, createSwimlane),
    takeLatest(DELETE_SWIMLANE, deleteSwimlane),
    takeLatest(UPDATE_SWIMLANE, updateSwimlane),
    takeLatest(GET_PREVIEW_RESULTS, previewSwimlane),
    takeLatest(GET_TAG_VALUES, getTagValues),
    takeLatest(
      GET_CONTROL_FRAMEWORKS_FOR_SWIMLANE,
      getControlFrameworksForSwimlane
    ),
    takeLatest(GET_BOT_VALUES, getBots),
    takeLatest(UPDATE_SWIMLANES_IN_CONTROL_FRAMEWORK, updateSwimlaneseInCF),
    takeLatest(UPDATE_USER_IN_SWIMLANE, updateUserInSwimlane),
  ])
}

export default swimlaneDetailsSaga
