import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'

import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { push } from 'connected-react-router'
import qs from 'query-string'
import _ from 'lodash'
import { Modal, ModalBody, ModalHeader, ModalFooter, Alert } from 'reactstrap'

import CloudBadge from 'components/CloudBadge'
import permissionChecker from 'containers/PermissionChecker'
import { ToolbarItem } from 'components/BulkActionToolbar'
import {
  selectIsUpdatingBotAssignment,
  selectBots,
} from 'containers/SonraiData/selectors'
import { updateBotAssignments, getBots } from 'containers/SonraiData/actions'
import SectionHeader from 'components/SectionHeader'
import TextLink from 'components/TextLink'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import { List, fromJS } from 'immutable'
import DataTable from 'components/DataTable'
import Button from 'components/Button'
import Icon from 'components/Icon'
import SelectBar from 'components/SelectBar'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import { getAcceptableCloudAccountTypesForBots } from 'utils/sonraiUtils'

import { setSwimlaneBotError as setError, updateSwimlane } from './actions'
import { selectSwimlaneBotError, selectUpdatingSwimlanes } from './selectors'
class SwimlaneBots extends Component {
  styles = {
    contentWrapper: {
      height: '100%',
      display: 'grid',
      gridTemplateRows: 'auto auto auto 1fr',
      gridTemplateAreas: '"header" "instructions" "error" "table"',
    },
    body: {
      gridArea: 'table',
    },
    alert: { marginTop: '0.25em', marginBottom: '-0.5em', gridArea: 'error' },
    preventionYes: {
      color: this.props.theme.success,
      borderColor: this.props.theme.success,
      marginRight: '1em',
    },
    preventionNah: {
      color: this.props.theme.fail,
      borderColor: this.props.theme.fail,
      marginRight: '1em',
    },
    swimlaneTitleWrapper: {
      gridArea: 'header',
      display: 'grid',
      gridTemplateColumns: '1fr auto auto',
    },
  }

  constructor(props) {
    super(props)
    if (props.allBots.get('data').isEmpty()) {
      props.getBots({ filters: {} })
    }
    this.state = {
      selectedBots: List(),
      addBotsSelected: [],
      addBotModalOpen: false,
      errorMsg: null,
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.error && !prevState.errorMsg) {
      this.setError(prevProps.error)
    }
  }

  toggleBotModal = () => {
    this.setState(prevState => ({
      addBotModalOpen: !prevState.addBotModalOpen,
    }))
  }

  closeAddBotModal = () => {
    this.setState({ addBotModalOpen: false, addBotsSelected: [] })
  }

  handleAddBotsSelectChange = bots => {
    if (!bots) {
      this.setState({ addBotsSelected: [] })
    } else {
      this.setState({ addBotsSelected: bots })
    }
  }

  addBots = () => {
    this.props.updateBotAssignments({
      swimlaneSrn: this.props.swimlane.get('srn'),
      add: this.state.addBotsSelected.map(bot => {
        return {
          botSrn: bot.value,
          contentSrn: this.props.swimlane.get('srn'),
        }
      }),
    })
    this.setState({ addBotModalOpen: false })
  }

  unassignBots = () => {
    this.props.updateBotAssignments({
      swimlaneSrn: this.props.swimlane.get('srn'),
      remove: this.state.selectedBots.toJS(),
    })
    this.setState({ selectedBots: List() })
  }

  getBotBadge = botSrn => {
    const bot = this.props.allBots
      .get('data', List())
      .find(bot => bot.get('srn') === botSrn)

    return (
      <span>
        <CloudBadge type={bot.get('cloud').toLowerCase()} />
        &nbsp;{bot.get('title')}
      </span>
    )
  }

  getModalBody = () => {
    const swimlaneAccounts = this.props.swimlane.get('accounts') || List()
    const currentBotSrns = (
      this.props.swimlane.get('bots') || List()
    ).map(bot => bot.get('srn'))
    const accountTypes = getAcceptableCloudAccountTypesForBots(
      swimlaneAccounts,
      this.props.accounts
    )
    const allBots = this.props.allBots
      .get('data')
      .filter(item => item)
      .filter(bot => {
        if (!_.isEmpty(accountTypes)) {
          if (accountTypes.includes(bot.get('cloud').toLowerCase())) {
            return true
          } else {
            return false
          }
        }
        return true
      })
      .map(bot => ({
        value: bot.get('srn'),
        label: bot.get('title'),
      }))
      .filter(guy => !currentBotSrns.includes(guy.value))

    if (this.props.allBots.get('isLoading')) {
      return <SquareLoadingAnimation />
    }

    return (
      <div style={{ marginBottom: '1em' }}>
        <SelectBar
          isMulti
          options={allBots}
          onChange={this.handleAddBotsSelectChange}
          value={this.state.addBotsSelected}
          formatOptionLabel={option => this.getBotBadge(option.value)}
        />
      </div>
    )
  }

  formatData = () => {
    return this.props.swimlane
      .get('bots')
      .toJS()
      .map(bot => {
        return {
          cloud: bot.cloud,
          name: bot.title,
          description: bot.description,
          srn: bot.srn,
          botAssignmentSrn: bot.botAssignmentSrn,
        }
      })
  }

  handleSelectionChanged = event => {
    const rows = event.api.getSelectedRows() || []
    const selectedBots = []
    rows.forEach(row => {
      selectedBots.push(row.botAssignmentSrn)
    })
    this.setState({
      selectedBots: fromJS(selectedBots),
    })
  }

  clearError = () => {
    this.setState({ errorMsg: null })
  }

  setError = msg => {
    this.setState({
      errorMsg: msg,
      selectedBots: List(),
      addBotsSelected: [],
      addBotModalOpen: false,
    })
    this.props.setError(null)
    setTimeout(() => {
      this.setState({ errorMsg: null })
    }, 5000)
  }

  handleBotNav = srn => {
    if (srn) {
      this.props.push({
        pathname: '/App/BotManagement/BotDetails',
        search: qs.stringify({
          botId: srn,
        }),
      })
    }
  }

  toggleBotsEnabled = () => {
    this.props.updateSwimlane({
      srn: this.props.swimlane.get('srn'),
      swimlane: {
        ...this.props.swimlane.toJS(),
        preventionEnabled: this.props.swimlane.get('preventionEnabled')
          ? false
          : true,
      },
    })
  }

  render() {
    const canAssign = this.props.userHasPermission({
      permissionName: 'assign.bots',
      resourceId: this.props.swimlane.get('resourceId'),
    })

    return (
      <div style={this.styles.contentWrapper}>
        <div style={this.styles.swimlaneTitleWrapper}>
          <SectionHeader>Prevention Bots</SectionHeader>
          <div>
            <span
              style={{
                fontStyle: 'italic',
                marginRight: '1em',
                fontSize: '0.9em',
              }}
            >
              Preventions are{' '}
              <strong>
                {this.props.swimlane.get('preventionEnabled')
                  ? 'Enabled'
                  : 'Disabled'}
              </strong>
            </span>
            <WithPermission permissionName="assign.bots">
              <Button
                disabled={
                  this.props.isUpdating ||
                  this.props.updatingSwimlanes.get(
                    this.props.swimlane.get('srn')
                  )
                }
                onClick={this.toggleBotsEnabled}
                style={
                  this.props.swimlane.get('preventionEnabled')
                    ? this.styles.preventionNah
                    : this.styles.preventionYes
                }
              >
                {this.props.swimlane.get('preventionEnabled')
                  ? 'Disable'
                  : 'Enable'}
                &nbsp;Prevention Bots
              </Button>
            </WithPermission>
          </div>

          <WithPermission
            permissionName="assign.bots"
            resourceId={this.props.swimlane.get('resourceId')}
          >
            <Button
              disabled={
                this.props.isUpdating ||
                this.props.updatingSwimlanes.get(this.props.swimlane.get('srn'))
              }
              onClick={this.toggleBotModal}
              color="primary"
            >
              <Icon fa name="plus" /> Add Bot
            </Button>
          </WithPermission>
        </div>
        <p>
          Prevention bots apply cloud-native policies to all accounts included
          in this swimlane. These policies can block functionality in your
          environment, so carefully review which preventions you enable.
        </p>
        {this.state.errorMsg && (
          <Alert
            style={this.styles.alert}
            color="danger"
            toggle={this.clearError}
          >
            {this.state.errorMsg}
          </Alert>
        )}
        <div style={this.styles.body}>
          <DataTable
            autosize={false}
            bulkActions={
              canAssign
                ? [
                    <ToolbarItem
                      permission="assign.bots"
                      key="unassign"
                      onClick={this.unassignBots}
                    >
                      Remove Bot(s)
                    </ToolbarItem>,
                  ]
                : undefined
            }
            checkboxSelectedRows={
              canAssign ? this.state.selectedBots.toJS() : undefined
            }
            bulkActionWorking={this.props.isUpdating}
            data={this.formatData()}
            onClickNodeView={this.handleBotNav}
            customColumnConfig={{
              srn: {
                hide: true,
              },
              description: {
                flex: 1,
              },
              cloud: {
                cellRendererFramework: ({ value }) => {
                  if (!value || typeof value !== 'string') {
                    return value
                  }

                  return (
                    <span>
                      <CloudBadge type={value.toLowerCase()} /> &nbsp;{value}
                    </span>
                  )
                },
              },
              botAssignmentSrn: {
                hide: true,
              },
            }}
            hasCheckBoxes={canAssign}
            selectionChanged={this.handleSelectionChanged}
          />
        </div>
        <Modal isOpen={this.state.addBotModalOpen} toggle={this.toggleBotModal}>
          <ModalHeader toggle={this.toggleBotModal}>Select Bot(s)</ModalHeader>
          <ModalBody>{this.getModalBody()}</ModalBody>
          <ModalFooter>
            <TextLink
              color="secondary"
              onClick={this.closeAddBotModal}
              style={{ marginRight: '1em' }}
            >
              Cancel
            </TextLink>
            <WithPermission permissionName="assign.bots">
              <Button
                disabled={_.isEmpty(this.state.addBotsSelected)}
                onClick={this.addBots}
                color="primary"
              >
                Add Bot(s)
              </Button>
            </WithPermission>
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

SwimlaneBots.propTypes = {
  isUpdating: PropTypes.bool,
  getBots: PropTypes.func,
  setError: PropTypes.func,
  updateBotAssignments: PropTypes.func,
  error: PropTypes.string,
  allBots: ImmutablePropTypes.map,
  updatingSwimlanes: ImmutablePropTypes.map,
  userHasPermission: PropTypes.func.isRequired,
  swimlane: ImmutablePropTypes.map,
  theme: themeShape,
  push: PropTypes.func,
  updateSwimlane: PropTypes.func,
  accounts: ImmutablePropTypes.iterable,
}

const mapStateToProps = createStructuredSelector({
  isUpdating: selectIsUpdatingBotAssignment,
  error: selectSwimlaneBotError,
  updatingSwimlanes: selectUpdatingSwimlanes,
  allBots: selectBots,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
      updateBotAssignments,
      updateSwimlane,
      getBots,
      setError,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect, permissionChecker)(themeable(SwimlaneBots))
