import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import qs from 'query-string'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import TextLink from 'components/TextLink'
import { Map } from 'immutable'
import DataTable from 'components/DataTable'
import CreatedByBadge from 'components/CreatedByBadge'
import Button from 'components/Button'
import Icon from 'components/Icon'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Badge,
} from 'reactstrap'
import SelectBar from 'components/SelectBar'

class SwimlaneControlFrameworks extends Component {
  constructor(props) {
    super(props)
    this.state = {
      manageModalOpen: false,
      cfSelection: [],
      propCfSelection: [],
      errorMsg: null,
    }
    this.styles = {
      container: {
        display: 'grid',
        gridTemplateRows: 'auto 1fr',
        height: '100%',
        gridRowGap: '1em',
      },
      topGuy: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
      },
      mainTitle: {
        fontSize: '1.5em',
      },
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.togglingCFs &&
      !this.props.togglingCFs &&
      !this.props.toggleError
    ) {
      this.setState({ cfSelection: [], propCfSelection: [] })
      this.closeManageModal()
    }

    if (
      !prevProps.toggleError &&
      this.props.toggleError &&
      !this.state.errorMsg
    ) {
      this.setState({ errorMsg: this.props.toggleError })
      this.props.clearError({ srn: this.props.swimlane.get('srn') })
      this.errorCountDown()
    }
  }

  errorCountDown = () => {
    setTimeout(() => {
      this.setState({ errorMsg: null })
    }, 5000)
  }

  openManageModal = frameworks => {
    const selection = frameworks
      .toList()
      .filter(
        cf =>
          cf.get('swimlaneSRNs') &&
          cf.get('swimlaneSRNs').includes(this.props.swimlane.get('srn'))
      )
      .map(cf => ({ value: cf.get('srn'), label: cf.get('title') }))

    this.setState({
      cfSelection: selection,
      propCfSelection: selection,
    })
    this.setState({ manageModalOpen: true })
  }

  closeManageModal = () => {
    this.setState({ manageModalOpen: false })
  }

  getData = frameworks => {
    return frameworks
      .toList()
      .map(framework => ({
        title: framework.get('title') || '-',
        description: framework.get('description') || '-',
        createdBy: framework.get('createdBy'),
        lastModified: framework.get('lastModified') || '-',
        srn: framework.get('srn'),
        scope: {
          global:
            framework.get('swimlaneSRNs') &&
            framework
              .get('swimlaneSRNs')
              .contains(this.props.swimlane.get('srn'))
              ? false
              : true,
        },
      }))
      .toJS()
  }

  updateCfSelection = params => {
    if (!params) {
      this.setState({ cfSelection: [] })
    } else {
      this.setState({ cfSelection: params })
    }
  }

  renderManageModal = () => {
    const options = this.props.controlGroups
      .toList()
      .filter(
        cf =>
          cf.get('createdBy') &&
          !cf.get('createdBy').includes('supersonrai') &&
          cf.get('enabled')
      )
      .toJS()
      .map(cf => ({ value: cf.srn, label: cf.title }))

    return (
      <div>
        <div>Select Control Framework(s)</div>
        <SelectBar
          options={options}
          onChange={this.updateCfSelection}
          value={this.state.cfSelection}
          isMulti
          disabled={this.props.togglingCFs}
        />
      </div>
    )
  }

  getIsDiffSelection = () => {
    let diff = false
    this.state.propCfSelection.forEach(sel => {
      if (
        this.state.cfSelection.findIndex(cf => cf.value === sel.value) === -1
      ) {
        diff = true
      }
    })
    this.state.cfSelection.forEach(sel => {
      if (
        this.state.propCfSelection.findIndex(cf => cf.value === sel.value) ===
        -1
      ) {
        diff = true
      }
    })
    return diff
  }

  applySwimlaneToCFs = () => {
    if (this.getIsDiffSelection()) {
      const add = []
      const remove = []
      this.state.propCfSelection.forEach(sel => {
        if (
          this.state.cfSelection.findIndex(cf => cf.value === sel.value) === -1
        ) {
          remove.push(sel.value)
        }
      })
      this.state.cfSelection.forEach(sel => {
        if (
          this.state.propCfSelection.findIndex(cf => cf.value === sel.value) ===
          -1
        ) {
          add.push(sel.value)
        }
      })

      this.props.toggleSwimlaneOnCFs({
        srn: this.props.swimlane.get('srn'),
        add,
        remove,
      })
    }
  }

  renderTable = frameworks => {
    if (!this.props.isLoaded) {
      return (
        <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
          <SquareLoadingAnimation />
        </div>
      )
    }

    return (
      <DataTable
        data={this.getData(frameworks)}
        hiddenColumns={['srn']}
        customColumnConfig={{
          scope: {
            width: 105,
            minWidth: 105,
            aggFunc: null,
            pinned: 'right',
            headerName: '',
            enableRowGroup: false,
            menuTabs: [],
            suppressMenu: true,
            cellRendererFramework: params => {
              if (!params.data) {
                return null
              }
              return (
                <Badge color="primary">
                  {params.value.global ? 'Global' : 'Applied'}
                </Badge>
              )
            },
          },
          title: {
            minWidth: 300,
            cellRendererFramework: ({ data }) => {
              if (!data) {
                return
              }
              const { srn, title } = data
              return (
                <TextLink
                  color="primary"
                  to={{
                    pathname: '/App/ControlCenter/ControlGroup',
                    search: qs.stringify({
                      controlGroupId: srn,
                    }),
                  }}
                >
                  {title}
                </TextLink>
              )
            },
          },
          createdBy: {
            cellRendererFramework: ({ value, data }) => {
              if (!data) {
                return
              }

              return <CreatedByBadge createdBy={value} table />
            },
          },
        }}
      />
    )
  }

  render() {
    const allFrameworks = this.props.controlGroups || Map()

    const frameworks = allFrameworks.filter(item => {
      return (
        (!item.get('swimlaneSRNs') && item.get('enabled')) ||
        (item.get('swimlaneSRNs') &&
          item.get('swimlaneSRNs').includes(this.props.swimlane.get('srn')))
      )
    })
    return (
      <div style={this.styles.container}>
        <div style={this.styles.topGuy}>
          <div style={this.styles.mainTitle}>
            Control Frameworks Scoped To Swimlane
          </div>
          <div>
            <Button
              disabled={this.props.togglingCFs}
              onClick={() => this.openManageModal(frameworks)}
              color="primary"
            >
              {this.props.togglingCFs && <Icon fa spin name="sync" />}
              &nbsp;Manage Applied Control Frameworks
            </Button>
          </div>
        </div>
        {this.renderTable(frameworks)}
        {this.state.manageModalOpen && (
          <Modal
            isOpen={this.state.manageModalOpen}
            toggle={this.closeManageModal}
          >
            <ModalHeader>
              {this.state.errorMsg && (
                <Alert color="danger">{this.state.errorMsg}</Alert>
              )}
              <div>Manage Applied Control Frameworks on Swimlane</div>
            </ModalHeader>
            <ModalBody>{this.renderManageModal()}</ModalBody>
            <ModalFooter>
              <TextLink color="primary" onClick={this.closeManageModal}>
                Close
              </TextLink>
              <Button
                disabled={this.props.togglingCFs || !this.getIsDiffSelection()}
                color="primary"
                onClick={this.applySwimlaneToCFs}
              >
                Save
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    )
  }
}

SwimlaneControlFrameworks.propTypes = {
  controlGroups: ImmutablePropTypes.map,
  swimlane: ImmutablePropTypes.map,
  isLoaded: PropTypes.bool,
  toggleSwimlaneOnCFs: PropTypes.func,
  togglingCFs: PropTypes.bool,
  toggleError: PropTypes.string,
  clearError: PropTypes.func,
}

export default SwimlaneControlFrameworks
