import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import ImmutableProptypes from 'react-immutable-proptypes'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'
import Button from 'components/Button'
import Icon from 'components/Icon'
import {
  slackActionTypeKey,
  slackActionTypes,
} from 'containers/PlatformSettingsData/constants'

import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import _ from 'lodash'

const componentsForCreatable = {
  DropdownIndicator: null,
}

const createOption = label => ({
  label,
  value: label,
})
const getOptions = things =>
  things.map(thing => ({
    label: slackActionTypeKey[thing] ? slackActionTypeKey[thing] : thing,
    value: thing,
  }))

const compareValues = (original, current) => {
  let isDifferent = false
  for (let o of original) {
    if (!current.includes(o)) {
      isDifferent = true
    }
  }
  for (let c of current) {
    if (!original.includes(c)) {
      isDifferent = true
    }
  }
  return isDifferent
}

const SlackInputs = props => {
  const [triggerValue, setTriggerValue] = useState(
    getOptions(props.actionTypes.toJS())
  )
  const [channelValue, setChannelValue] = useState(
    getOptions(props.channels.toJS())
  )
  const [channelTextValue, setChannelTextValue] = useState('')

  const handleKeyDown = event => {
    if (!channelTextValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setChannelTextValue('')
        setChannelValue([...channelValue, createOption(channelTextValue)])
        event.preventDefault()
    }
  }

  const isDisabled = () => {
    const hasChanges =
      compareValues(
        triggerValue.map(type => type.value),
        props.actionTypes.toJS()
      ) ||
      compareValues(
        channelValue.map(channel => channel.value),
        props.channels.toJS()
      )
    const isEmpty = _.isEmpty(channelValue) || _.isEmpty(triggerValue)
    if (!isEmpty && hasChanges && !props.loading && !props.deleting) {
      return false
    }
    return true
  }

  return (
    <Fragment>
      <div>
        <div>On Trigger(s)</div>
        <Select
          onChange={triggers =>
            !triggers ? setTriggerValue([]) : setTriggerValue(triggers)
          }
          options={slackActionTypes}
          defaultValue={triggerValue}
          isMulti
          isClearable={false}
          isDisabled={props.loading || props.deleting}
        />
        {_.isEmpty(triggerValue) && (
          <div
            style={{
              color: props.theme.warn,
              fontSize: '0.85rem',
              margin: '0.25rem 0rem 0rem 0.25rem',
            }}
          >
            You must have at least ONE Trigger.
          </div>
        )}
      </div>
      <div>
        <div>Notify Channel(s)</div>
        <CreatableSelect
          components={componentsForCreatable}
          onChange={channels =>
            !channels ? setChannelValue([]) : setChannelValue(channels)
          }
          onInputChange={setChannelTextValue}
          menuIsOpen={false}
          inputValue={channelTextValue}
          isClearable={false}
          onKeyDown={handleKeyDown}
          value={channelValue}
          placeholder="Enter valid Slack Channel(s) and hit enter..."
          isMulti
          isDisabled={props.loading || props.deleting}
        />
        {_.isEmpty(channelValue) && (
          <div
            style={{
              color: props.theme.warn,
              fontSize: '0.85rem',
              margin: '0.25rem 0rem 0rem 0.25rem',
            }}
          >
            You must have at least ONE Channel.
          </div>
        )}
      </div>

      <div>
        <Button
          style={{ marginTop: '1em', width: '190px' }}
          color="primary"
          onClick={() =>
            props.saveValues({
              actionTypes: triggerValue.map(type => type.value),
              channels: channelValue.map(channel => channel.value),
            })
          }
          disabled={isDisabled()}
        >
          {props.loading ? (
            <Fragment>
              <Icon fa spin name="sync" />
              &nbsp;Saving Changes
            </Fragment>
          ) : (
            <Fragment>
              <Icon fa name="save" />
              &nbsp;Save Changes
            </Fragment>
          )}
        </Button>
      </div>
    </Fragment>
  )
}

SlackInputs.propTypes = {
  theme: themeShape,
  actionTypes: ImmutableProptypes.list,
  channels: ImmutableProptypes.list,
  saveValues: PropTypes.func,
  loading: PropTypes.bool,
  deleting: PropTypes.bool,
}

export default themeable(SlackInputs)
