import React, { Fragment } from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { Button, Form, FormGroup, Label, Input } from 'reactstrap'
import ImportanceWidget from 'components/ImportanceWidget'
import Icon from 'components/Icon'
import TextLink from 'components/TextLink'
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'

export class EditDetails extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      swimlaneTitle: props.swimlane.get('title'),
      swimlaneDescription: props.swimlane.get('description'),
      swimlaneImportance: props.swimlane.get('defaultImportance'),
      swimlaneNames: [],
      nameIsValid: false,
    }

    this.styles = {
      submit: {
        margin: '1em 0',
        float: 'right',
      },
      modalBody: {
        maxHeight: '70vh',
        overflow: 'auto',
      },
    }
  }

  doSave = () => {
    this.props.save({
      srn: this.props.swimlane.get('srn'),
      swimlane: {
        ...this.props.swimlane.toJS(),
        title: this.state.swimlaneTitle,
        description: this.state.swimlaneDescription,
        defaultImportance: this.state.swimlaneImportance,
      },
    })
  }

  setSwimlaneImportance = ({ importance }) => {
    this.setState({
      swimlaneImportance: importance,
    })
  }

  setSwimlaneTitle = e => {
    this.setState({
      swimlaneTitle: e.target.value,
    })
  }

  setSwimlaneDescription = e => {
    this.setState({
      swimlaneDescription: e.target.value,
    })
  }

  renderButtons = nameIsValid => {
    // Buttons should always be positive action on the right,
    // because people are use to things moving to the right as moving forward, and left as backwards
    // shut up nerd
    if (this.props.updating) {
      return (
        <Button color="primary" disabled>
          <Icon fa name="sync" spin />
        </Button>
      )
    }

    return (
      <Fragment>
        <div style={{ float: 'left', width: '100%' }}>
          {/* <div style={{ float: 'right', width: '100%' }}> */}
          <div style={{ float: 'right' }}>
            <TextLink
              color="secondary"
              onClick={this.props.close}
              style={{ marginRight: '1em' }}
            >
              Cancel
            </TextLink>
            <Button
              color="primary"
              onClick={this.doSave}
              disabled={!nameIsValid}
            >
              Save
            </Button>
          </div>
        </div>
      </Fragment>
    )
  }

  isNameValid = () => {
    const { swimlane, swimlanes } = this.props
    const title = this.state.swimlaneTitle.trim().toLowerCase()

    if (title === 'all' || /\s/g.test(title)) {
      return false
    }

    let filteredSwimlanes = swimlanes

    if (swimlanes) {
      if (swimlane) {
        filteredSwimlanes = filteredSwimlanes.filter(
          sl => sl.get('srn') !== swimlane.get('srn')
        )
      }

      if (
        filteredSwimlanes.find(
          swimlane => swimlane.get('title', '').toLowerCase() === title
        )
      ) {
        return false
      }
    }

    return true
  }

  render() {
    const nameIsValid = this.isNameValid()

    return (
      <div>
        <div>
          <ModalHeader toggle={this.props.toggleModal}>
            Edit Swimlane Details
          </ModalHeader>
          <ModalBody style={this.styles.modalBody}>
            <Form>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  type="text"
                  name="name"
                  id="name"
                  onChange={this.setSwimlaneTitle}
                  placeholder="Enter Swimlane Name"
                  value={this.state.swimlaneTitle}
                />
              </FormGroup>
              <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  type="textarea"
                  name="description"
                  id="description"
                  onChange={this.setSwimlaneDescription}
                  value={this.state.swimlaneDescription}
                />
              </FormGroup>
              <FormGroup>
                <Label for="importance">Default Importance</Label>
                <ImportanceWidget
                  id={'importance'}
                  theme={this.props.theme}
                  nodeId={this.props.swimlane.get('srn')}
                  setImportance={this.setSwimlaneImportance}
                  importance={this.state.swimlaneImportance}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter> {this.renderButtons(nameIsValid)} </ModalFooter>
        </div>
      </div>
    )
  }
}

EditDetails.propTypes = {
  theme: themeShape,
  swimlane: ImmutablePropTypes.map,
  swimlanes: ImmutablePropTypes.map,
  save: PropTypes.func,
  close: PropTypes.func,
  toggleModal: PropTypes.func,
  updating: PropTypes.bool,
}

export default themeable(EditDetails)
