import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { push } from 'connected-react-router'

import {
  selectSonraiUsers,
  selectSonraiUsersLoading,
} from 'containers/SonraiData/selectors'
import { selectAssigningUsers } from './selectors'
import { updateUserInSwimlane } from './actions'

import { List, fromJS } from 'immutable'
import WithPermission from 'containers/PermissionChecker/WithPermission'
import Button from 'components/Button'
import DataTable from 'components/DataTable'
import { ToolbarItem } from 'components/BulkActionToolbar'
import SectionHeader from 'components/SectionHeader'
import Icon from 'components/Icon'
import qs from 'query-string'
import _ from 'lodash'
import AddAssignmentModal from './AddAssignmentModal'

export class SwimlaneUsers extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedUserAssignmentSrns: List(),
      userRoleModalOpen: false,
    }
    this.styles = {
      swimlaneTitleWrapper: {
        display: 'grid',
        gridTemplateColumns: '1fr auto',
      },
      tabTitle: {
        fontSize: '1.2em',
        fontWeight: '400',
      },
      body: {
        marginTop: '1em',
        height: '100%',
      },
    }
  }

  toggleUserRoleModal = () => {
    this.setState(prevState => ({
      userRoleModalOpen: !prevState.userRoleModalOpen,
    }))
  }

  closeUserRoleModal = () => {
    this.setState({ userRoleModalOpen: false })
  }

  handleUserSelectionChange = event => {
    const rows = event.api.getSelectedRows() || []
    const selectedUserAssignmentSrns = []
    rows.forEach(row => {
      selectedUserAssignmentSrns.push({
        assignmentSrn: row.assignmentSrn,
        userSrn: row.userSrn,
      })
    })
    this.setState({
      selectedUserAssignmentSrns: fromJS(selectedUserAssignmentSrns),
    })
  }

  handleUserNav = srn => {
    if (srn) {
      this.props.push({
        pathname: '/App/UserManagement/User',
        search: qs.stringify({
          srn,
        }),
      })
    }
  }

  unassignUsers = () => {
    this.props.updateUserInSwimlane({
      delete: this.state.selectedUserAssignmentSrns.toJS(),
    })
    this.setState({ selectedUserAssignmentSrns: List() })
  }

  render() {
    if (this.props.usersLoading) {
      return null
    }

    const users = this.props.sonraiUsers
      .toList()
      .map(user => {
        if (
          user
            .getIn(['roleAssignments', 'items'], List())
            .findIndex(
              item => item.get('scope') === this.props.swimlaneResourceId
            ) !== -1
        ) {
          return user
        } else {
          return null
        }
      })
      .filter(item => !!item)
      .toJS()

    const data = []
    users.forEach(user => {
      user.roleAssignments.items.forEach(item => {
        if (item.scope === this.props.swimlaneResourceId) {
          data.push({
            name: user.name,
            email: user.email,
            role: _.get(item, ['role', 'items', 0, 'name'], ''),
            userSrn: user.srn,
            assignmentSrn: item.srn,
          })
        }
      })
    })

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
          height: '100%',
          gridRowGap: '1em',
        }}
      >
        <div style={this.styles.swimlaneTitleWrapper}>
          <SectionHeader>Users Scoped to Swimlane</SectionHeader>
          <WithPermission permissionName="edit.swimlanes">
            <Button
              disabled={this.props.assigningUsers}
              onClick={this.toggleUserRoleModal}
              color="primary"
            >
              <Icon fa name="plus" /> &nbsp; Add User
            </Button>
          </WithPermission>
        </div>
        <div style={{ height: '100%' }}>
          <DataTable
            autosize={false}
            bulkActionWorking={this.props.assigningUsers}
            bulkActions={[
              <ToolbarItem
                key="unassign"
                permission="edit.swimlanes"
                onClick={this.unassignUsers}
              >
                Unassign User(s)
              </ToolbarItem>,
            ]}
            checkboxSelectedRows={this.state.selectedUserAssignmentSrns.toJS()}
            data={data}
            hiddenColumns={['assignmentSrn', 'userSrn']}
            multiRowSelect
            hasCheckBoxes
            onClickNodeView={this.handleUserNav}
            selectionChanged={this.handleUserSelectionChange}
          />
        </div>

        {this.state.userRoleModalOpen && (
          <AddAssignmentModal
            swimlaneResourceId={this.props.swimlaneResourceId}
            toggle={this.closeUserRoleModal}
          />
        )}
      </div>
    )
  }
}

SwimlaneUsers.propTypes = {
  sonraiUsers: ImmutablePropTypes.map,
  usersLoading: PropTypes.bool,
  assigningUsers: PropTypes.bool,
  push: PropTypes.func,
  swimlaneResourceId: PropTypes.string,
  updateUserInSwimlane: PropTypes.func,
}

const mapStateToProps = createStructuredSelector({
  sonraiUsers: selectSonraiUsers,
  usersLoading: selectSonraiUsersLoading,
  assigningUsers: selectAssigningUsers,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
      updateUserInSwimlane,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default compose(withConnect)(SwimlaneUsers)
