import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { Input } from 'reactstrap'
import BorderlessButton from 'components/BorderlessButton'

const JiraInputs = props => {
  const [projectKey, setProjectKey] = useState(props.projectKey)
  const [putLabels, setPutLabels] = useState(props.putLabels)

  const compareValues = () => {
    return projectKey === props.projectKey && putLabels === props.putLabels
  }

  return (
    <Fragment>
      <div>
        <div>Project Key</div>
        <Input
          value={projectKey}
          onChange={params => setProjectKey(params.target.value)}
          disabled={props.loading || props.deleting}
        />
      </div>
      <div>
        <div>Put Labels</div>
        <div style={{ marginLeft: '2em', display: 'flex' }}>
          <BorderlessButton onClick={() => setPutLabels(true)}>
            <Input
              type="radio"
              name="radio1"
              checked={putLabels}
              disabled={props.loading || props.deleting}
            />{' '}
            Yes
          </BorderlessButton>
          <BorderlessButton
            onClick={() => setPutLabels(false)}
            style={{ marginLeft: '2em' }}
          >
            <Input
              type="radio"
              name="radio1"
              disabled={props.loading || props.deleting}
              checked={!putLabels}
            />{' '}
            No
          </BorderlessButton>
        </div>
      </div>

      <div>
        <Button
          style={{ marginTop: '1em', width: '190px' }}
          color="primary"
          onClick={() =>
            props.saveValues({
              putLabels: putLabels,
              projectKey: projectKey,
            })
          }
          disabled={compareValues()}
        >
          {props.loading ? (
            <Fragment>
              <Icon fa spin name="sync" />
              &nbsp;Saving Changes
            </Fragment>
          ) : (
            <Fragment>
              <Icon fa name="save" />
              &nbsp;Save Changes
            </Fragment>
          )}
        </Button>
      </div>
    </Fragment>
  )
}

JiraInputs.propTypes = {
  saveValues: PropTypes.func,
  loading: PropTypes.bool,
  deleting: PropTypes.bool,
  projectKey: PropTypes.string,
  putLabels: PropTypes.bool,
}

export default JiraInputs
