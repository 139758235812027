import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List, Map } from 'immutable'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { bindActionCreators } from 'redux'
import FormLabel from 'components/FormLabel'
import {
  selectIntegrations,
  selectIntegrationsLoading,
  selectDeletingIntegrationAssignments,
  selectAssigningIntegrations,
  selectUpdatingConfigs,
} from 'containers/PlatformSettingsData/selectors'

import {
  getIntegrations,
  assignIntegration,
  deleteIntegrationAssignment,
  updateIntegrationConfig,
} from 'containers/PlatformSettingsData/actions'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import BorderedCard from 'components/BorderedCard'
import Button from 'components/Button'
import BorderlessButton from 'components/BorderlessButton'
import TextLink from 'components/TextLink'
import Icon from 'components/Icon'
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Alert,
} from 'reactstrap'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { get, startCase } from 'lodash'
import SlackInputs from './SlackInputs'
import JiraInputs from './JiraInputs'
import messages from './messages'
import { slackActionTypes } from 'containers/PlatformSettingsData/constants'

export class SwimlaneIntegrations extends React.Component {
  constructor(props) {
    super(props)
    if (props.integrations.isEmpty()) {
      props.getIntegrations()
    }
    this.state = {
      isAddModalOpen: false,
      selectedIntegration: null,
      slackChannels: [],
      slackChannelText: '',
      slackTriggers: [],
      slackInputs: Map(),
      jiraPutLabels: true,
      jiraProjectKey: '',
    }
  }

  styles = {
    container: {
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      rowGap: '1em',
      height: '100%',
    },
    cardBox: {
      overflowY: 'auto',
      height: '100%',
    },
    topGuy: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
    },
    cardTop: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr auto',
    },
    slackMid: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      columnGap: '3em',
    },
    title: {
      fontSize: '1.2em',
    },
    mainTitle: {
      fontSize: '1.5em',
    },
    formLabel: {},
    formTemplate: {
      display: 'grid',
      rowGap: '1em',
    },
  }

  setJiraPutLabels = value => {
    this.setState({ jiraPutLabels: value })
  }

  handleJiraProjectKeyChange = params => {
    this.setState({ jiraProjectKey: params.target.value })
  }

  handleSlackActionTypeChange = params => {
    this.setState({ slackTriggers: params })
  }

  handleChangeChannel = value => {
    if (!value) {
      this.setState({ slackChannels: [] })
    } else {
      this.setState({ slackChannels: value })
    }
  }

  handleChangeChannelText = value => {
    this.setState({ slackChannelText: value })
  }
  createOption = label => ({
    label,
    value: label,
  })

  handleKeyDown = event => {
    const { slackChannelText, slackChannels } = this.state
    if (!slackChannelText) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        this.setState({
          slackChannelText: '',
          slackChannels: [
            ...slackChannels,
            this.createOption(slackChannelText),
          ],
        })
        event.preventDefault()
    }
  }

  componentsForCreatable = {
    DropdownIndicator: null,
  }

  openAddModal = () => this.setState({ isAddModalOpen: true })

  closeAddModal = () =>
    this.setState({
      isAddModalOpen: false,
      selectedIntegration: null,
      slackChannels: [],
      slackChannelText: '',
      slackTriggers: [],
    })

  handleIntegrationChange = params => {
    this.setState({
      selectedIntegration: params,
      slackChannels: [],
      slackChannelText: '',
      slackTriggers: [],
    })
  }

  getIntegrationsForSwombo = () => {
    return this.props.integrations.filter(int => {
      if (int.get('configs')) {
        let assignments = List()
        int.get('configs').forEach(config => {
          if (config.get('assignment')) {
            assignments = assignments.merge(config.get('assignment'))
          }
        })
        if (
          assignments.find(
            ass => ass.get('SwimlaneSRN') === this.props.swimlaneSrn
          )
        ) {
          return true
        }
      }
      return false
    })
  }

  deleteAssignment = (intSrn, configSrn) => {
    this.props.deleteIntegrationAssignment({ intSrn, assignmentSrn: configSrn })
  }

  renderAddModal = () => {
    const options = this.getAvailableIntegrations()
      .map(int => ({
        label: `${int.get('title')} (${startCase(
          int.get('type').toLowerCase()
        )})`,
        value: int,
      }))
      .toJS()

    const type = get(this.state, ['selectedIntegration', 'value', 'type'], null)
      ? startCase(
          get(
            this.state,
            ['selectedIntegration', 'value', 'type'],
            ''
          ).toLowerCase()
        )
      : null

    return (
      <div style={this.styles.formTemplate}>
        <div>
          <Alert color="primary">
            <div style={{ color: 'black' }}>
              {messages.AssignmentWarning.defaultMessage}
            </div>
          </Alert>
          <div style={{ fontWeight: 400 }}>Select Integration</div>
          <Select
            value={this.state.selectedIntegration}
            onChange={this.handleIntegrationChange}
            options={options}
          />
        </div>
        {type === 'Slack' ? (
          <Fragment>
            <div>
              <FormLabel for="onTrigger" required label={'On Trigger(s)'} />
              <Select
                onChange={this.handleSlackActionTypeChange}
                options={slackActionTypes}
                isMulti
                isClearable
              />
            </div>
            <div>
              <FormLabel
                for="notifyChannels"
                required
                label={'Notify Channel(s)'}
              />

              <CreatableSelect
                components={this.componentsForCreatable}
                isMulti
                onChange={this.handleChangeChannel}
                onInputChange={this.handleChangeChannelText}
                menuIsOpen={false}
                inputValue={this.state.slackChannelText}
                isClearable
                onKeyDown={this.handleKeyDown}
                value={this.state.slackChannels}
                placeholder="Enter valid Slack Channel(s) and hit enter..."
              />
            </div>
          </Fragment>
        ) : (
          type === 'Jira' && (
            <Fragment>
              <div>
                <div style={this.styles.formLabel}>Project Key</div>
                <Input
                  value={this.state.jiraProjectKey}
                  onChange={this.handleJiraProjectKeyChange}
                />
              </div>
              <div>
                <div style={this.styles.formLabel}>Put Labels</div>
                <div style={{ marginLeft: '2em', display: 'flex' }}>
                  <BorderlessButton onClick={() => this.setJiraPutLabels(true)}>
                    <Input
                      type="radio"
                      name="radio1"
                      checked={this.state.jiraPutLabels}
                    />{' '}
                    Yes
                  </BorderlessButton>
                  <BorderlessButton
                    onClick={() => this.setJiraPutLabels(false)}
                    style={{ marginLeft: '2em' }}
                  >
                    <Input type="radio" name="radio1" /> No
                  </BorderlessButton>
                </div>
              </div>
            </Fragment>
          )
        )}
      </div>
    )
  }

  updateConfig = (intSrn, srn, params) => {
    this.props.updateIntegrationConfig({
      srn,
      intSrn,
      slack: { actionTypes: params.actionTypes, channels: params.channels },
      type: 'SLACK',
    })
  }

  updateJiraConfig = (intSrn, srn, params) => {
    this.props.updateIntegrationConfig({
      srn,
      intSrn,
      jira: {
        ProjectKey: params.projectKey,
        PutLabels: params.putLabels,
      },
      type: 'JIRA',
    })
  }

  getSlackCard = (int, index) => {
    const config = int
      .getIn(['configs'], List())
      .find(
        config =>
          config.getIn(['assignment', 0, 'SwimlaneSRN']) ===
          this.props.swimlaneSrn
      )

    return (
      <div key={`integration-${index}`}>
        <BorderedCard style={{ marginTop: '1em' }} title={int.get('title')}>
          <div style={this.styles.cardTop}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon
                style={{ width: '1.5em', height: '1.5em' }}
                png
                type="slacklogo"
              />
            </div>
            <div
              style={{ ...this.styles.title, marginLeft: '1em' }}
            >{`"${int.get('title')}" ${startCase(
              int.get('type').toLowerCase()
            )} Workspace`}</div>
            <BorderlessButton
              color="danger"
              onClick={() =>
                this.deleteAssignment(int.get('srn'), config.get('srn'))
              }
              disabled={this.props.deletingAssignments.get(config.get('srn'))}
            >
              <Icon fa name="times" />
            </BorderlessButton>
          </div>
          <div style={this.styles.slackMid}>
            <SlackInputs
              actionTypes={config.getIn(['slack', 'actionTypes'], List())}
              channels={config.getIn(['slack', 'channels'], List())}
              saveValues={params =>
                this.updateConfig(int.get('srn'), config.get('srn'), params)
              }
              loading={this.props.updatingConfigs.get(config.get('srn'))}
              deleting={this.props.deletingAssignments.get(config.get('srn'))}
            />
          </div>
        </BorderedCard>
      </div>
    )
  }

  getJiraCard = (int, index) => {
    const config = int
      .getIn(['configs'], List())
      .find(
        config =>
          config.getIn(['assignment', 0, 'SwimlaneSRN']) ===
          this.props.swimlaneSrn
      )

    return (
      <div key={`integration-${index}`}>
        <BorderedCard style={{ marginTop: '1em' }} title={int.get('title')}>
          <div style={this.styles.cardTop}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon
                style={{ width: '1.5em', height: '1.5em' }}
                png
                type="jiralogo"
              />
            </div>
            <div
              style={{ ...this.styles.title, marginLeft: '1em' }}
            >{`"${int.get('title')}" ${startCase(
              int.get('type').toLowerCase()
            )} Project`}</div>
            <BorderlessButton
              color="danger"
              onClick={() =>
                this.deleteAssignment(int.get('srn'), config.get('srn'))
              }
              disabled={this.props.deletingAssignments.get(config.get('srn'))}
            >
              <Icon fa name="times" />
            </BorderlessButton>
          </div>
          <div style={this.styles.slackMid}>
            <JiraInputs
              saveValues={params =>
                this.updateJiraConfig(int.get('srn'), config.get('srn'), params)
              }
              loading={this.props.updatingConfigs.get(config.get('srn'))}
              deleting={this.props.deletingAssignments.get(config.get('srn'))}
              putLabels={config.getIn(['jira', 'PutLabels'])}
              projectKey={config.getIn(['jira', 'ProjectKey'])}
            />
          </div>
        </BorderedCard>
      </div>
    )
  }

  getServiceNowCard = (int, index) => {
    const config = int
      .getIn(['configs'], List())
      .find(
        config =>
          config.getIn(['assignment', 0, 'SwimlaneSRN']) ===
          this.props.swimlaneSrn
      )

    return (
      <div key={`integration-${index}`}>
        <BorderedCard style={{ marginTop: '1em' }} title={int.get('title')}>
          <div style={this.styles.cardTop}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Icon
                style={{ width: '1.5em', height: '1.5em' }}
                png
                type="servicenowlogo"
              />
            </div>
            <div
              style={{ ...this.styles.title, marginLeft: '1em' }}
            >{`"${int.get('title')}" ${startCase(
              int.get('type').toLowerCase()
            )} Platform`}</div>
            <BorderlessButton
              color="danger"
              onClick={() =>
                this.deleteAssignment(int.get('srn'), config.get('srn'))
              }
            >
              <Icon fa name="times" />
            </BorderlessButton>
          </div>
        </BorderedCard>
      </div>
    )
  }

  getAvailableIntegrations = () => {
    return this.props.integrations.filter(int => {
      if (!int.get('configs') || int.get('configs').isEmpty()) {
        return true
      }
      if (
        !int.get('configs').find(config => {
          return !config.get('assignment').find(ass => {
            return ass.get('SwimlaneSRN') === this.props.swimlaneSrn
          })
        })
      ) {
        return false
      } else {
        return true
      }
    })
  }

  assignIntegration = () => {
    if (this.state.selectedIntegration) {
      this.props.assignIntegration({
        swimlaneSrn: this.props.swimlaneSrn,
        integrationSrn: this.state.selectedIntegration.value.srn,
        slack: {
          actionTypes: this.state.slackTriggers.map(trig => trig.value),
          channels: this.state.slackChannels.map(chan => chan.value),
        },
        jira: {
          ProjectKey: this.state.jiraProjectKey,
          PutLabels: this.state.jiraPutLabels,
        },
        type: this.state.selectedIntegration.value.type,
      })
      this.closeAddModal()
    }
  }

  getIsAddDisabled = () => {
    if (
      this.state.selectedIntegration &&
      this.state.selectedIntegration.value.type === 'SERVICE_NOW'
    ) {
      return false
    }
    if (
      this.state.selectedIntegration &&
      this.state.selectedIntegration.value.type === 'JIRA'
    ) {
      if (!this.state.jiraProjectKey) {
        return true
      } else {
        return false
      }
    }
    if (
      !this.state.selectedIntegration ||
      !this.state.slackChannels ||
      !this.state.slackTriggers ||
      this.state.slackChannels.length < 1 ||
      this.state.slackTriggers.length < 1
    ) {
      return true
    } else {
      return false
    }
  }

  render() {
    if (this.props.loading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SquareLoadingAnimation />
        </div>
      )
    }

    const theseInts = this.getIntegrationsForSwombo()

    return (
      <div style={this.styles.container}>
        <div style={this.styles.topGuy}>
          <div style={this.styles.mainTitle}>Integration Settings</div>
          <div>
            <Button
              disabled={this.props.assigningIntegrations.get(
                this.props.swimlaneSrn
              )}
              onClick={this.openAddModal}
              color="primary"
            >
              <Icon fa name="plus" />
              &nbsp;Add Destination
            </Button>
          </div>
        </div>
        <div style={this.styles.cardBox}>
          {theseInts.map((int, index) =>
            int.get('type') === 'SLACK'
              ? this.getSlackCard(int, index)
              : int.get('type') === 'SERVICE_NOW'
              ? this.getServiceNowCard(int, index)
              : int.get('type') === 'JIRA' && this.getJiraCard(int, index)
          )}
        </div>
        {this.state.isAddModalOpen && (
          <Modal isOpen={this.state.isAddModalOpen} toggle={this.closeAddModal}>
            <ModalHeader>Add Destination For Swimlane</ModalHeader>
            <ModalBody>{this.renderAddModal()}</ModalBody>
            <ModalFooter>
              <TextLink color="primary" onClick={this.closeAddModal}>
                Close
              </TextLink>
              <Button
                disabled={this.getIsAddDisabled()}
                color="primary"
                onClick={this.assignIntegration}
              >
                Add
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </div>
    )
  }
}

SwimlaneIntegrations.propTypes = {
  getIntegrations: PropTypes.func,
  deleteIntegrationAssignment: PropTypes.func,
  assignIntegration: PropTypes.func,
  updateIntegrationConfig: PropTypes.func,
  swimlaneSrn: PropTypes.string,
  integrations: ImmutablePropTypes.list,
  deletingAssignments: ImmutablePropTypes.map,
  updatingConfigs: ImmutablePropTypes.map,
  assigningIntegrations: ImmutablePropTypes.map,
  loading: PropTypes.bool,
}

const mapStateToProps = createStructuredSelector({
  integrations: selectIntegrations,
  loading: selectIntegrationsLoading,
  deletingAssignments: selectDeletingIntegrationAssignments,
  assigningIntegrations: selectAssigningIntegrations,
  updatingConfigs: selectUpdatingConfigs,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getIntegrations,
      assignIntegration,
      deleteIntegrationAssignment,
      updateIntegrationConfig,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)

export default withConnect(SwimlaneIntegrations)
