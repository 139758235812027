/*
 *
 * SwimlaneManager reducer
 *
 */

import { fromJS, Map } from 'immutable'
import { handleActions } from 'redux-actions'

// import {
//   FETCH_SWIMLANES,
//   FETCH_SWIMLANES_SUCCESS,
// } from 'containers/SonraiData/constants'

import {
  UPDATE_SWIMLANE,
  UPDATE_SWIMLANE_SUCCESS,
  SET_UPDATE_SWIMLANE_ERROR,
  GET_TAG_VALUES,
  SET_TAG_VALUES,
  GET_CONTROL_FRAMEWORKS_FOR_SWIMLANE,
  SET_CONTROL_FRAMEWORKS_FOR_SWIMLANE,
  SET_BOT_VALUES,
  UPDATE_SWIMLANES_IN_CONTROL_FRAMEWORK,
  UPDATE_SWIMLANES_IN_CONTROL_FRAMEWORK_SUCCESS,
  UPDATE_USER_IN_SWIMLANE,
  UPDATE_USER_IN_SWIMLANE_SUCCESS,
  SET_SWIMLANE_BOT_ERROR,
} from './constants'

const initialState = fromJS({
  controlFrameworks: Map(),
  creatingSwimlane: false,
  swimlanesLoading: false,
  editingSwimlaneSrn: null,
  updatingSwimlanes: Map(),
  updatingSwimlaneError: Map(),
  assigningUsers: false,
  loadingTagValues: false,
  tagValues: null,
  botValues: [],
  swimlaneBotError: null,
})

const swimlaneDetailsReducer = handleActions(
  {
    [UPDATE_SWIMLANE]: (state, { payload }) =>
      state
        .setIn(['updatingSwimlanes', payload.srn], true)
        .deleteIn(['updatingSwimlaneError', payload.srn]),
    [UPDATE_SWIMLANE_SUCCESS]: (state, { payload }) =>
      state
        .deleteIn(['updatingSwimlanes', payload.srn])
        .deleteIn(['updatingSwimlaneError', payload.srn]),
    [SET_UPDATE_SWIMLANE_ERROR]: (state, { payload }) =>
      state
        .deleteIn(['updatingSwimlanes', payload.srn])
        .setIn(['updatingSwimlaneError', payload.srn], payload.error),
    [SET_BOT_VALUES]: (state, { payload }) =>
      state.set('botValues', fromJS(payload)),
    [GET_TAG_VALUES]: state => state.set('loadingTagValues', true),
    [SET_TAG_VALUES]: (state, { payload }) =>
      state.set('loadingTagValues', false).set('tagValues', fromJS(payload)),
    [GET_CONTROL_FRAMEWORKS_FOR_SWIMLANE]: (state, { payload }) =>
      state.setIn(
        ['controlFrameworks', payload.swimlane],
        Map({ data: null, loading: true })
      ),
    [SET_CONTROL_FRAMEWORKS_FOR_SWIMLANE]: (state, { payload }) =>
      state
        .setIn(
          ['controlFrameworks', payload.swimlane, 'data'],
          fromJS(payload.data)
        )
        .setIn(['controlFrameworks', payload.swimlane, 'loading'], false),
    [UPDATE_SWIMLANES_IN_CONTROL_FRAMEWORK]: state => state,
    [UPDATE_SWIMLANES_IN_CONTROL_FRAMEWORK_SUCCESS]: (state, { payload }) =>
      state.update('controlFrameworks', framework =>
        framework.get('srn') === payload.srn ? payload.framework : framework
      ),
    [UPDATE_USER_IN_SWIMLANE]: state => state.set('assigningUsers', true),
    [UPDATE_USER_IN_SWIMLANE_SUCCESS]: state =>
      state.set('assigningUsers', false),
    [SET_SWIMLANE_BOT_ERROR]: (state, { payload }) =>
      state.set('swimlaneBotError', payload),
  },
  initialState
)

export default swimlaneDetailsReducer
