import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { compose, bindActionCreators } from 'redux'
import { push } from 'connected-react-router'

import { GLOBAL_SWIMLANE_NAME } from 'appConstants'
import SectionHeader from 'components/SectionHeader'
import injectReducer from 'utils/injectReducer'
import injectSaga from 'utils/injectSaga'

import reducer from './reducer'
import sagas from './sagas'

import {
  selectSwimlanesLoading,
  selectTags,
  selectAccounts,
  selectSwimlanes,
} from 'containers/SonraiData/selectors'

import {
  selectControlGroups,
  selectLoadedControlGroups,
  selectUpdatingCFs,
  selectTogglingSwimlaneOnCfs,
  selectTogglingSwimlaneError,
} from 'containers/ControlFrameworkData/selectors'
import {
  toggleSwimlaneOnCFs,
  toggleSwimlaneOnCFsSuccess,
} from 'containers/ControlFrameworkData/actions'

import {
  selectUpdatingSwimlanes,
  selectUpdatingSwimlanesError,
} from './selectors'

import { removeSwimlane, fetchTags } from 'containers/SonraiData/actions'
import { getTagValues, updateSwimlane, setUpdateSwimlaneError } from './actions'

import WithPermission from 'containers/PermissionChecker/WithPermission'
import themeable, { themeShape } from 'containers/ThemeManager/Themeable'
import color from 'color'
import BorderedCard from 'components/BorderedCard'
import { Helmet } from 'react-helmet'
import { getNameForHelmet } from 'utils/sonraiUtils'
import { Breadcrumb, BreadcrumbItem, Alert } from 'reactstrap'
import BorderlessButton from 'components/BorderlessButton'
import Button from 'components/Button'
import Icon from 'components/Icon'
import { NodeViewTabs } from 'components/NodeView'
import SquareLoadingAnimation from 'components/SquareLoadingAnimation'
import TextLink from 'components/TextLink'
import EditDetails from './EditDetails'
import SwimlaneBots from './SwimlaneBots'
import SwimlaneUsers from './SwimlaneUsers'
import SwimlaneIntegrations from './SwimlaneIntegrations'
import SwimlaneControlFrameworks from './SwimlaneControlFrameworks'
import EditFilters from './EditFilters'
import { Modal, ModalBody } from 'reactstrap'
import qs from 'query-string'
import CreatedByBadge from 'components/CreatedByBadge'
import SwimlaneFilterDisplay from './SwimlaneFilterDisplay'

class SwimlaneDetails extends React.Component {
  constructor(props) {
    super(props)

    if (props.tags.isEmpty()) {
      props.fetchTags()
    }

    this.state = {
      editOpen: false,
      deletePromptOpen: false,
      editFilterOpen: false,
    }
  }

  styles = {
    container: {
      width: '100%',
      height: '100%',
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      gridRowGap: '0.5em',
      padding: '1rem',
      overflow: 'auto',
    },
    breadcrumbs: {
      fontSize: '1rem',
      margin: '0rem 0rem 0.5rem 0rem',
    },
    swimlaneTitleWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr auto',
    },
    swimlaneTitle: {
      fontWeight: 400,
      fontSize: '1.5rem',
    },
    swimlaneDescription: {},
    defaultImportance: {
      fontSize: '0.9em',
      margin: '0.25rem 0rem',
      paddingRight: '0.75rem',
      textAlign: 'right',
    },
    empty: {
      fontStyle: 'italic',
      color: this.props.theme.neutralMedium,
    },
    containers: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0.75rem',
    },
    containerTitles: {
      fontSize: '0.95rem',
      textAlign: 'left',
      fontWeight: '400',
      marginBottom: '0.5rem',
      alignSelf: 'flex-start',
    },
    body: {
      height: '100%',
    },
    tabContainer: {
      height: '100%',
      padding: '1rem',
      backgroundColor: this.props.theme.light,
      borderTop: 0,
      borderBottom: `1px solid ${this.props.theme.neutralMedium}`,
      borderLeft: `1px solid ${this.props.theme.neutralMedium}`,
      borderRight: `1px solid ${this.props.theme.neutralMedium}`,
    },
    button: {
      height: '1.85rem',
      fontSize: '0.85rem',
      border: `1px solid ${this.props.theme.primary}`,
      padding: '0.25rem 0.5rem',
      margin: '4px 5px 0px 0px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: color(this.props.theme.neutralDark).darken(0.6),
    },
    actions: {
      display: 'grid',
      gridTemplateColumns: 'auto auto',
    },
  }

  toggleEditOpen = () => {
    this.setState(prevState => ({ editOpen: !prevState.editOpen }))
  }

  toggleEditFilterOpen = () => {
    this.setState(prevState => ({ editFilterOpen: !prevState.editFilterOpen }))
  }

  toggleDeletePromptOpen = () => {
    this.setState(prevState => ({
      deletePromptOpen: !prevState.deletePromptOpen,
    }))
  }

  toggleFrameworkModal = () => {
    this.setState(prevState => ({
      frameworkModalOpen: !prevState.frameworkModalOpen,
    }))
  }

  closeEdit = () => {
    this.setState({ editOpen: false })
  }

  closeDelete = () => {
    this.setState({ deletePromptOpen: false })
  }

  closeEditFilter = () => {
    this.setState({ editFilterOpen: false })
  }

  saveDetails = params => {
    this.props.updateSwimlane(params)
    this.closeEdit()
  }

  isGlobalSwimlane = swimlane => {
    return swimlane.get('title') === GLOBAL_SWIMLANE_NAME
  }

  renderTitle = swimlane => {
    if (!swimlane) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SquareLoadingAnimation />
        </div>
      )
    }

    let controlsStyle = {}
    if (this.isGlobalSwimlane(swimlane)) {
      controlsStyle.display = 'none'
    }

    return (
      <div style={this.styles.swimlaneTitleWrapper}>
        <div style={this.styles.swimlaneTitle}>{swimlane.get('title')}</div>
        <div style={controlsStyle}>
          <WithPermission
            permissionName="edit.swimlanes"
            resourceId={swimlane.get('resourceId')}
          >
            <div style={this.styles.actions}>
              <BorderlessButton
                onClick={this.toggleEditOpen}
                style={this.styles.button}
                disabled={this.props.updating.get(swimlane.get('srn'))}
              >
                <div style={{ paddingRight: '0.5rem' }}>
                  <Icon
                    fa
                    name="pencil-alt"
                    style={{
                      fontSize: '0.95rem',
                      color: this.props.theme.primary,
                    }}
                  />
                </div>
                Edit Swimlane
              </BorderlessButton>
              <BorderlessButton
                onClick={this.toggleDeletePromptOpen}
                style={this.styles.button}
                disabled={this.props.updating.get(swimlane.get('srn'))}
              >
                <div style={{ paddingRight: '0.5rem' }}>
                  <Icon
                    fa
                    name="minus-circle"
                    style={{
                      fontSize: '0.95rem',
                      color: this.props.theme.fail,
                    }}
                  />
                </div>
                Delete Swimlane
              </BorderlessButton>
            </div>
          </WithPermission>
        </div>

        <Modal isOpen={this.state.editOpen}>
          <EditDetails
            save={this.saveDetails}
            swimlane={swimlane}
            toggle={this.toggleEditOpen}
            close={this.closeEdit}
            swimlanes={this.props.swimlanes}
            updating={this.props.updating.get(swimlane.get('srn'))}
          />
        </Modal>
      </div>
    )
  }

  renderDetails = swimlane => {
    return (
      <div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr  0.25fr',
            padding: '0.5rem 0.5rem 0.75rem 0.5rem',
          }}
        >
          <div style={this.styles.swimlaneDescription}>
            <span>
              {swimlane.get('description') || (
                <span style={this.styles.empty}>( No Description )</span>
              )}
            </span>
          </div>
          <div style={this.styles.defaultImportance}>
            Default Importance:{' '}
            <span style={{ fontWeight: '400' }}>
              {swimlane.get('defaultImportance')}
            </span>
          </div>
        </div>
        <div
          style={{
            padding: '0rem 0rem 0rem 0.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
          }}
        >
          <div
            style={{
              marginRight: '0.5rem',
              fontSize: '0.9em',
            }}
          >
            {' '}
            Created By:{' '}
          </div>
          <CreatedByBadge createdBy={swimlane.get('createdBy')} />
        </div>
      </div>
    )
  }

  renderFilters = swimlane => {
    const tags = swimlane.get('tags') || List()
    const names = swimlane.get('names') || List()
    const resources = swimlane.get('resourceIds') || List()
    const accounts = swimlane.get('accounts') || List()
    const hasNoFilters =
      tags.isEmpty() &&
      names.isEmpty() &&
      resources.isEmpty() &&
      accounts.isEmpty()

    return (
      <div
        style={{
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
          gridRowGap: '1em',
        }}
      >
        <div style={this.styles.swimlaneTitleWrapper}>
          <SectionHeader>Swimlane Filters</SectionHeader>
          {!this.isGlobalSwimlane(swimlane) && (
            <WithPermission permissionName="edit.swimlanes">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {hasNoFilters && (
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon
                      fa
                      name="exclamation-circle"
                      color={this.props.theme.warn}
                    />
                    <span
                      style={{
                        color: this.props.theme.warn,
                        fontSize: '0.85rem',
                        margin: '0rem 1rem 0rem 0.5rem',
                      }}
                    >
                      No resources will match this Swimlane unless at least one
                      filter is applied.
                    </span>
                  </div>
                )}
                <Button
                  onClick={this.toggleEditFilterOpen}
                  disabled={this.props.updating.get(swimlane.get('srn'))}
                  color="primary"
                >
                  Edit Filters
                </Button>
              </div>
            </WithPermission>
          )}
        </div>
        <SwimlaneFilterDisplay swimlane={swimlane} />
        <Modal
          isOpen={this.state.editFilterOpen}
          toggle={this.toggleEditFilterOpen}
          size="lg"
          style={{
            maxWidth: '900px',
            width: '80vw',
          }}
        >
          <EditFilters
            save={this.saveDetails}
            swimlane={swimlane}
            toggle={this.toggleEditFilterOpen}
            close={this.closeEditFilter}
            swimlanes={this.props.swimlanes}
            accounts={this.props.accounts}
            tags={this.props.tags}
            tagValues={this.props.tagValues}
            getTagValues={this.props.getTagValues}
            updating={this.props.updating.get(swimlane.get('srn'))}
          />
        </Modal>
      </div>
    )
  }

  renderBody = swimlane => {
    const startTab =
      qs.parse(this.props.location.search).tab === 'integrations' ? 3 : 0
    return (
      <NodeViewTabs
        style={{
          minHeight: '100%',
          display: 'grid',
          gridTemplateRows: 'auto 1fr',
        }}
        activeTabId={startTab}
      >
        <div label="Filters">
          <div style={this.styles.tabContainer}>
            {this.renderFilters(swimlane)}
          </div>
        </div>
        <div label="Bots">
          <div style={this.styles.tabContainer}>
            <SwimlaneBots
              accounts={this.props.accounts}
              allBots={this.props.allBots}
              swimlane={swimlane}
            />
          </div>
        </div>
        <div label="Users" style={this.styles.tabContainer}>
          <div style={this.styles.tabContainer}>
            <SwimlaneUsers swimlaneResourceId={swimlane.get('resourceId')} />
          </div>
        </div>
        <div label="Integrations" style={this.styles.tabContainer}>
          <div style={this.styles.tabContainer}>
            <SwimlaneIntegrations swimlaneSrn={swimlane.get('srn')} />
          </div>
        </div>
        <div label="Control Frameworks">
          <div style={this.styles.tabContainer}>
            <SwimlaneControlFrameworks
              swimlane={swimlane}
              controlGroups={this.props.controlGroups}
              isLoaded={this.props.controlGroupsLoaded}
              toggleSwimlaneOnCFs={this.props.toggleSwimlaneOnCFs}
              togglingCFs={this.props.togglingSwimlaneOnCfs.get(
                swimlane.get('srn')
              )}
              toggleError={this.props.togglingSwimlaneError.get(
                swimlane.get('srn')
              )}
              clearError={this.props.toggleSwimlaneOnCFsSuccess}
              updatingCFs={this.props.updatingCFs}
            />
          </div>
        </div>
      </NodeViewTabs>
    )
  }

  clearError = srn => {
    this.props.setUpdateSwimlaneError({ srn: srn, error: null })
  }

  removeSwimlane = swimlane => {
    this.props.removeSwimlane({
      srn: swimlane.get('srn'),
      title: swimlane.get('title'),
    })
    this.props.push({ pathname: '/App/SwimlaneManagement' })
  }

  render() {
    if (this.props.swimlanesLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SquareLoadingAnimation />
        </div>
      )
    }

    const srn = qs.parse(this.props.location.search).srn
    if (!srn) {
      this.props.push({ pathname: '/App/SwimlaneManagement' })
    }

    if (this.props.swimlanesLoading) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <SquareLoadingAnimation />
        </div>
      )
    }

    const swimlane = this.props.swimlanes.find(
      swimmi => swimmi.get('srn') === srn
    )

    return (
      <div style={this.styles.container}>
        <div>
          <div style={this.styles.breadcrumbs}>
            <Breadcrumb>
              <BreadcrumbItem
                style={{ cursor: 'pointer', color: this.props.theme.primary }}
                onClick={() =>
                  this.props.push({ pathname: '/App/SwimlaneManagement' })
                }
              >
                All Swimlanes
              </BreadcrumbItem>
              <BreadcrumbItem>Swimlane Details</BreadcrumbItem>
            </Breadcrumb>
          </div>
          {this.props.updatingError.get(swimlane.get('srn')) && (
            <Alert
              isOpen={!!this.props.updatingError.get(swimlane.get('srn'))}
              color="danger"
              toggle={() => this.clearError(swimlane.get('srn'))}
            >
              {this.props.updatingError.get(swimlane.get('srn'))}
            </Alert>
          )}
          <BorderedCard
            style={{
              opacity: this.props.updating.get(swimlane.get('srn'))
                ? '0.5'
                : '1',
            }}
          >
            {this.renderTitle(swimlane)}
            {this.renderDetails(swimlane)}
          </BorderedCard>
        </div>

        <div style={this.styles.body}>{this.renderBody(swimlane)}</div>
        {this.state.deletePromptOpen && (
          <Modal isOpen={true} toggle={this.toggleDeletePromptOpen}>
            <ModalBody>
              Confirm Swimlane Deletion
              <div style={{ float: 'left', width: '100%' }}>
                <div style={{ float: 'right' }}>
                  <TextLink
                    color="secondary"
                    onClick={this.closeDelete}
                    style={{ marginRight: '1em' }}
                  >
                    Cancel
                  </TextLink>
                  <Button
                    color="danger"
                    onClick={() => this.removeSwimlane(swimlane)}
                  >
                    Delete Swimlane
                  </Button>
                </div>
              </div>
            </ModalBody>
          </Modal>
        )}
        {swimlane && (
          <Helmet title={`Sonrai - ${getNameForHelmet(swimlane.toJS())}`} />
        )}
      </div>
    )
  }
}

SwimlaneDetails.propTypes = {
  theme: themeShape,
  removeSwimlane: PropTypes.func,
  push: PropTypes.func,
  getTagValues: PropTypes.func,
  fetchTags: PropTypes.func,
  setUpdateSwimlaneError: PropTypes.func,
  updateSwimlane: PropTypes.func,
  updating: ImmutablePropTypes.map,
  updatingError: ImmutablePropTypes.map,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  swimlanes: ImmutablePropTypes.map,
  allBots: ImmutablePropTypes.map,
  tags: ImmutablePropTypes.iterable,
  accounts: ImmutablePropTypes.iterable,
  swimlanesLoading: PropTypes.bool,
  tagValues: ImmutablePropTypes.iterable,
  controlGroups: ImmutablePropTypes.iterable,
  controlGroupsLoaded: PropTypes.bool,
  updatingCFs: ImmutablePropTypes.map,
  toggleSwimlaneOnCFs: PropTypes.func,
  toggleSwimlaneOnCFsSuccess: PropTypes.func,
  togglingSwimlaneOnCfs: ImmutablePropTypes.map,
  togglingSwimlaneError: ImmutablePropTypes.map,
}

const mapStateToProps = createStructuredSelector({
  swimlanes: selectSwimlanes,
  swimlanesLoading: selectSwimlanesLoading,
  updating: selectUpdatingSwimlanes,
  updatingError: selectUpdatingSwimlanesError,
  tags: selectTags,
  accounts: selectAccounts,
  controlGroups: selectControlGroups,
  controlGroupsLoaded: selectLoadedControlGroups,
  updatingCFs: selectUpdatingCFs,
  togglingSwimlaneOnCfs: selectTogglingSwimlaneOnCfs,
  togglingSwimlaneError: selectTogglingSwimlaneError,
})

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      push,
      removeSwimlane,
      getTagValues,
      updateSwimlane,
      fetchTags,
      setUpdateSwimlaneError,
      toggleSwimlaneOnCFs,
      toggleSwimlaneOnCFsSuccess,
    },
    dispatch
  )
}

const withConnect = connect(mapStateToProps, mapDispatchToProps)
const withReducer = injectReducer({ key: 'swimlaneDetails', reducer })
const withSaga = injectSaga({ key: 'swimlaneDetails', saga: sagas })

export default compose(
  withReducer,
  withConnect,
  withSaga,
  themeable
)(SwimlaneDetails)
