import { createSelector } from 'reselect'
import { Map, List } from 'immutable'
import { GLOBAL_SWIMLANE_NAME } from 'appConstants'
import { selectSwimlanes } from 'containers/SonraiData/selectors'

/**
 * Direct selector to the swimlaneDetails state domain
 */
const selectSwimlaneDetailsDomain = state =>
  state.get('swimlaneDetails') || Map()

export const selectPreviewResultsLoading = createSelector(
  selectSwimlaneDetailsDomain,
  swimlaneDomain => swimlaneDomain.get('loadingPreviewResults')
)

export const selectPreviewResults = createSelector(
  selectSwimlaneDetailsDomain,
  swimlaneDomain => swimlaneDomain.get('previewResults') || List()
)

export const selectCreatingSwimlane = createSelector(
  selectSwimlaneDetailsDomain,
  swimlaneDomain => swimlaneDomain.get('creatingSwimlane')
)

export const selectSwimlanesLoading = createSelector(
  selectSwimlaneDetailsDomain,
  swimlaneDomain => swimlaneDomain.get('swimlanesLoading')
)

export const selectEditingSwimlaneSrn = createSelector(
  selectSwimlaneDetailsDomain,
  swimlaneDomain => swimlaneDomain.get('editingSwimlaneSrn')
)

export const selectUpdatingSwimlanes = createSelector(
  selectSwimlaneDetailsDomain,
  swimlaneDomain => swimlaneDomain.get('updatingSwimlanes') || Map()
)

export const selectUpdatingSwimlanesError = createSelector(
  selectSwimlaneDetailsDomain,
  swimlaneDomain => swimlaneDomain.get('updatingSwimlaneError') || Map()
)

export const selectTagValues = createSelector(
  selectSwimlaneDetailsDomain,
  swimlaneDomain => swimlaneDomain.get('tagValues')
)

export const selectIsLoadingTagValues = createSelector(
  selectSwimlaneDetailsDomain,
  swimlaneDomain => swimlaneDomain.get('loadingTagValues')
)

export const selectSwimlaneControlFrameworks = createSelector(
  selectSwimlaneDetailsDomain,
  swimlaneDomain => swimlaneDomain.get('controlFrameworks') || Map()
)

export const selectBotValues = createSelector(
  selectSwimlaneDetailsDomain,
  swimlaneDomain => swimlaneDomain.get('botValues') || List()
)

export const selectAssigningUsers = createSelector(
  selectSwimlaneDetailsDomain,
  swimlaneDomain => swimlaneDomain.get('assigningUsers')
)

export const selectSwimlaneBotError = createSelector(
  selectSwimlaneDetailsDomain,
  swimlaneDomain => swimlaneDomain.get('swimlaneBotError')
)

export const selectSwimlanesWithoutGlobal = createSelector(
  selectSwimlanes,
  swimlanes =>
    swimlanes
      ? swimlanes.filterNot(
          swimlane => swimlane.get('title') === GLOBAL_SWIMLANE_NAME
        )
      : List()
)
